const base_url = process.env.REACT_APP_API_HR_URL

const page_args = data => {
  const page = `&page.page=${data.page.page}`
  const page_size = `&page.page_size=${data.page.page_size}`
  const order = `&order.column=${data.order.column}`
  const order_direction = `&order.direction=${data.order.direction}`
  const query = data.query ? `&query=${data.query}` : ''
  return `${page}${page_size}${order}${order_direction}${query}`
}

const auth_header = {
  'Authorization': `Bearer ${localStorage.getItem('token')}`
}

const build_entity = entity => {
  const get_page = async data =>
    fetch(`${base_url}${entity}/page?${page_args(data)}`, {
      headers: auth_header
    })
  const get_by_id = async id => 
    fetch(`${base_url}${entity}/${id}`, {
      headers: auth_header
    })
  const create = async data =>
    fetch(`${base_url}${entity}/`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        ...auth_header
      },
      body: JSON.stringify(data)
    })
  const update = async data =>
    fetch(`${base_url}${entity}/`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        ...auth_header
      },
      body: JSON.stringify(data)
    })
  const remove = async id =>
    fetch(`${base_url}${entity}/${id}`, {
      method: 'delete',
      headers: auth_header
    })
  return {
    get_page,
    get_by_id,
    create,
    update,
    remove
  }
}

export const companies = build_entity('companies')
export const department_tasks = build_entity('department_tasks')
department_tasks.get_all_by_department = async id =>
  fetch(`${base_url}department_tasks/all/${id}`, {
    headers: auth_header
  })
export const departments = build_entity('departments')
export const device_types = build_entity('device_types')
device_types.get_all = async () =>
  fetch(`${base_url}device_types/all/`, {
    headers: auth_header
  })
device_types.get_all_by_person = async person_id =>
  fetch(`${base_url}device_types/all_by_person/${person_id}`, {
    headers: auth_header
  })
export const devices = build_entity('devices')
devices.get_empty = async data =>
  fetch(`${base_url}devices/empty?${page_args(data)}`, {
    headers: auth_header
  })
devices.get_all_by_person_id = async person_id =>
  fetch(`${base_url}devices/person/${person_id}`, {
    headers: auth_header
  })
devices.get_all_empty_with_type = async (device_type, data) =>
  fetch(`${base_url}devices/empty_with_type/${device_type}?${page_args(data)}`, {
    headers: auth_header
  })

export const office_tasks = build_entity('office_tasks')
office_tasks.get_all_by_office = async id =>
  fetch(`${base_url}office_tasks/all/${id}`, {
    headers: auth_header
  })
export const offices = build_entity('offices')
const build_field = field => field ? `&field=${field}` : ''
const build_position_id = position_id => position_id ? `&position_id=${position_id}` : ''
export const persons = build_entity('persons')
persons.create = async (data, auth_data) =>
  fetch(`${base_url}persons/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      ...auth_header,
      'login': auth_data.login,
      'password': auth_data.password
    },
    body: JSON.stringify(data)
  })
persons.get_page = async (data, position_id, field) =>
  fetch(`${base_url}persons/page?${page_args(data)}${build_position_id(position_id)}${build_field(field)}`, {
    headers: auth_header
  })

export const position_categories = build_entity('position_categories')
position_categories.get_all = async () =>
  fetch(`${base_url}position_categories/all/`, {
    headers: auth_header
  })
export const positions = build_entity('positions')
positions.get_page = async (data, field) =>
  fetch(`${base_url}positions/page?${page_args(data)}${build_field(field)}`, {
    headers: auth_header
  })

export const avatars = {}
avatars.set_image = async (id, body) =>
  fetch(`${base_url}avatars/set/${id}`, {
    method: 'put',
    body,
    headers: {
      //'Content-Type': 'multipart/form-data',
      ...auth_header
    }
  })
avatars.unset_image = async id =>
  fetch(`${base_url}avatars/unset/${id}`, {
    method: 'put',
    headers: auth_header
  })
avatars.is_exist = async id =>
  fetch(`${base_url}avatars/is_exist/${id}`, {
    headers: auth_header
  })

