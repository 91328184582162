import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import '../css/DeviceForm.css'
import {
  persons as persons_api,
  device_types as device_types_api
} from 'API/hr'

import {useField} from 'hooks/useField'
import {fields} from 'utils/device.fields'
import {
  Title,
  Field,
  SearchField,
  DatePicker,
  Line,
  Button
} from 'components/HrForms'

const process_person = person => {
  if (!person) return
  const {
    first_name,
    family_name,
    last_name
  } = person
  return ({
    id: person.id,
    name: `${family_name} ${first_name} ${last_name}`
  })
}

const process_date = date => {
  if (!date || date === '') return null
  return new Date(date)
}

const DeviceForm = ({device, onSave}) => {
  const serial_number = useField('')
  const date_of_issue = useField()
  const contract = useField('')
  const user = useField()
  const device_type = useField()

  const [persons, set_persons] = useState([])
  const [device_types, set_device_types] = useState([])

  const navigate = useNavigate()

  const load_device_types = async () => {
    try {
      const loaded_res = await device_types_api
        .get_all()
        .then(res => res.json())
      if (loaded_res.Success) {
        set_device_types(loaded_res.Success)
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_persons = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await persons_api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_persons(loaded_res.Success.models.map(model => process_person(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    if (!device) return
    serial_number.change(device.serial_number)
    device_type.change(device.device_type)
    date_of_issue.change(process_date(device.date_of_issue))
    contract.change(device.contract)
    user.change(process_person(device.user))
  }, [device])

  const on_save = () => {
    const errors = []
    errors.push(serial_number.validate_empty())
    errors.push(device_type.validate_empty())
    //errors.push(date_of_issue.validate_empty())
    //errors.push(contract.validate_empty())
    if (errors.some(err => err)) return
    onSave({
      serial_number: serial_number.value,
      device_type: device_type.value.id,
      date_of_issue: date_of_issue.value?.toISOString().replace('Z', ''),
      contract: contract.value || null,
      user_id: user.value?.id
    })
  }
  
  return (
    <>
      <div className="hr_form device_form block-one">
        <Title>{fields.serial_number}</Title>
        <Field
          value={serial_number.value}
          isError={serial_number.is_error}
          onChange={e => serial_number.change(e.target.value)}
        />
        <Title>{fields.device_type}</Title>
        <SearchField
          value={device_type.value}
          values={device_types}
          isError={device_type.is_error}
          onSearch={load_device_types}
          onChange={val => device_type.change(val)}
        />
        <Title className="date_of_issue">{fields.date_of_issue}</Title>
        <DatePicker
          value={date_of_issue.value}
          isError={date_of_issue.is_error}
          onChange={date => date_of_issue.change(date)}
        />
      </div>
      <div className="hr_form device_form block-three">
        <Title>{fields.contract}</Title>
        <Field
          value={contract.value}
          isError={contract.is_error}
          onChange={e => contract.change(e.target.value)}
        />
        <Title>{fields.user}</Title>
        <SearchField
          value={user.value}
          values={persons}
          emptyValue
          isError={user.is_error}
          onSearch={load_persons}
          onChange={val => user.change(val)}
        />
      </div>
      <Line className="hr_form line" />
      <div className="hr_form device_form block-four">
        <Button className="save_button" onClick={on_save}>SAVE CHANGES</Button>
      </div>

    </>
  )
}

export default DeviceForm
