import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {persons as api} from 'API/hr'
import {fields, order, table} from 'utils/person.fields'
import {HR_EMPTY_VALUE, HR_PAGE_SIZE} from 'utils/constants'

import HrModal from 'components/HrModal'
import Header from '../components/Header'
import HrMenu from '../components/HrMenu'
import HrSearch from '../components/HrSearch'
import HrTable from '../components/HrTable'
import PersonCard from 'components/PersonCard'
import HrDialog from 'components/HrDialog'
import HrPagination from 'components/HrPagination'

import 'css/PersonTable.css'

const get_full_name = person => {
  const {
    family_name,
    first_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const process_model = model => {
  const res = {...model}
  res.full_name = get_full_name(model) 
  res.position = model.position?.name
  res.office = model.office?.full_name
  return res
}

const Persons = () => {
  const [models, set_models] = useState()
  const [total, set_total] = useState(0)
  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(HR_PAGE_SIZE)
  const [column, set_column] = useState()
  const [direction, set_direction] = useState('asc')
  const [query, set_query] = useState()

  const [person, set_person] = useState()
  const [remove_person, set_remove_person] = useState()

  const navigate = useNavigate()

  const load_persons = async () => {
    const args = {
      page: {page, page_size},
      order: {column: column || order[0], direction},
      query: query || ''
    }
    try {
      const loaded_res = await api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_models(loaded_res.Success.models.map(model => process_model(model)))
        set_total(loaded_res.Success.total.amount)
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_persons()
  }, [page, page_size, column, direction, query])

  const column_change = column => {
    if (!column) set_column()
    else set_column(column)
  }

  const direction_change = direction => {
    if (!direction) set_direction('asc')
    else (set_direction(direction))
  }

  const query_change = query => {
    if (!query) set_query('')
    else set_query(query)
  }

  const on_add = () => {
    navigate('/hr/person')
  }

  const on_select = async id => {
    try {
      const loaded_res = await api
        .get_by_id(id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_person(loaded_res.Success)
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Invalid employee ID', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const on_person_close = () => {
    set_person()
  }

  const on_person_edit = () => {
    navigate(`/hr/person/${person.id}`)
    set_person()
  }

  const on_person_remove = () => {
    set_remove_person(person)
    set_person()
  }

  const on_remove_close = () => {
    set_remove_person()
  }

  const on_remove_aprove = async () => {
    try {
      const remove_res = await api
        .remove(remove_person.id)
        .then(res => res.json())
      if (remove_res.Success) {
        load_persons()
        enqueueSnackbar('Employee successfully deleted', {variant: 'success'})
      } else if (remove_res.IsAbsent) {
        enqueueSnackbar('Invalid employee ID', {variant: 'error'})
      } else if (remove_res.NotEmpty) {
        enqueueSnackbar('A company with this name already exists', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_person()
  }

  const on_page_change = value => {
    set_page(value)
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Personnel records</Header>
      <HrMenu />
      <div className="hr_page">
        <HrSearch
          fields={fields}
          order={order}
          column={column}
          onColumnChange={column_change}
          direction={direction}
          onDirectionChange={direction_change}
          query={query}
          onQueryChange={query_change}
          addTitle="ADD A NEW EMPLOYEE"
          onAdd={on_add}
        />
        {models && models.length > 0 ? (
          <>
            <HrTable
              models={models}
              fields={fields}
              table={table}
              select={on_select}
              short={total > page_size}
              className="person"
            />
            {total > page_size ? (
              <HrPagination
                current={page}
                size={page_size}
                total={total}
                change={on_page_change}
              />
            ) : null}
          </>
        ) : (
          <div className="hr_page--empty">{HR_EMPTY_VALUE}</div>
        )}
      </div>
      {person ? (
        <HrModal
          title={get_full_name(person)}
          onClose={on_person_close}
          onEdit={on_person_edit}
          onRemove={on_person_remove}
        >
          <PersonCard className="hr_modal--card" person={person} />
        </HrModal>
      ) : null}
      {remove_person ? (
        <HrModal
          title="Delete"
          onClose={on_remove_close}
        >
          <HrDialog
            title={`Delete employee "${get_full_name(remove_person)}"?`}
            onRemove={on_remove_aprove}
            onCancel={on_remove_close}
          />
        </HrModal>
      ) : null}
    </div>
  )
}

export default Persons
