import {useState, useEffect} from 'react'
import {useParams} from "react-router-dom"
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {
  devices as api,
  persons as persons_api
} from 'API/hr'
import Header from 'components/Header'
import HrMenu from 'components/HrMenu'
import HrTitle from 'components/HrTitle'
import DeviceForm from 'components/DeviceForm'
import DeviceCard from 'components/DeviceCard'

import {HR_VIEW_MODE, HR_EDIT_MODE} from 'utils/constants'

const Device = () => {
  const [device, set_device] = useState()
  const [mode, set_mode] = useState(HR_EDIT_MODE)

  const {id, person_id} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const load_device = async () => {
    if (id) {
      try {
        const loaded_res = await api
          .get_by_id(id)
          .then(res => res.json())
        if (loaded_res.Success) {
          set_device(loaded_res.Success)
        } else if (loaded_res.IsAbsent) {
          enqueueSnackbar('Invalid device ID', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid query', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else if (person_id) {
      load_person()
    }
  }

  const load_person = async () => {
    try {
      const loaded_res = await persons_api
        .get_by_id(person_id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_device({
          serial_number: '',
          date_of_issue: '',
          sim_number: '',
          last_connection: '',
          contract: '',
          user: {
            id: loaded_res.Success.id,
            first_name: loaded_res.Success.first_name,
            family_name: loaded_res.Success.family_name,
            last_name: loaded_res.Success.last_name
          }
        })
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Invalid employee ID', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_device()
  }, [id])

  const save_device = async (values) => {
    if (id) {
      try {
        const save_res = await api
          .update({id: device.id, ...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('Device information updated', {variant: 'success'})
          load_device()
        } else if (save_res.IsAbsent) {
          enqueueSnackbar('Invalid device ID', {variant: 'error'})
        } else if (save_res.IsExistByField) {
          enqueueSnackbar('A device with this ID already exists', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else {
      try {
        const save_res = await api
          .create({...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('Device successfully saved', {variant: 'success'})
          navigate(`/hr/device/${save_res.Success}`)
        } else if (save_res.IsExist) {
          enqueueSnackbar('A device with this ID already exists', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    }
  }

  const on_view = () => set_mode(HR_VIEW_MODE)
  const on_edit = () => set_mode(HR_EDIT_MODE)

  const on_back = () => {
    navigate('/hr/devices')
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Personnel records</Header>
      <HrMenu />
      <div className="hr_page">
        <HrTitle
          mode={mode}
          onView={on_view}
          onEdit={on_edit}
          onBack={on_back}
        >{device ? device.serial_number : 'Device ID'}</HrTitle>
        {mode === HR_EDIT_MODE ? (
          <DeviceForm device={device} onSave={save_device} />
        ) : null}
        {mode === HR_VIEW_MODE ? (
          <DeviceCard className="hr_page--card" device={device} />
        ) : null}
      </div>
    </div>
  )
}

export default Device
