import {useState} from 'react'
import ClickAwayListener from 'react-click-away-listener'

import '../css/HrSearch.css'
import search_icon from 'assets/hr_search_2.svg'
import order_icon from 'assets/hr_order.svg'
import remove_icon from 'assets/hr_remove_order.svg'
import plus_icon from 'assets/hr_add_item.svg'

const HrSearch = (props) => {
  const {
    fields,
    order,
    column,
    onColumnChange,
    direction,
    onDirectionChange,
    query,
    onQueryChange,
    addTitle,
    onAdd
  } = props

  const [is_open, set_is_open] = useState(false)

  const open_options = () => {
    set_is_open(true)
  }

  const away_options = () => {
    set_is_open(false)
  }

  const select_option = option => () => {
    onColumnChange(option)
    set_is_open(false)
  }

  return (
    <div className="hr_search">
      <div className="hr_search--field">
        <img src={search_icon} className="hr_search--icon" />
        <input
          className="hr_search--input"
          value={query}
          onChange={e => onQueryChange(e.target.value)}
        />
      </div>
      <div className="hr_search--order">
        <img src={order_icon} className="hr_search--order__icon" onClick={open_options} />
        <div className="hr_search--order__title">Sort by</div>
        {column ? (
          <div className="hr_search--order__item">
            <div className="hr_search--order__value">{fields[column]}</div>
            <img
              src={remove_icon}
              className="hr_search--order__remove-icon"
              onClick={select_option()}
            />
          </div>
        ) : (
          <div className="hr_search--order__item">
            <div className="hr_search--order__value">Default</div>
          </div>
        )}
        {is_open ? (
          <ClickAwayListener onClickAway={away_options}>
            <div className="hr_search--order__options">
              {order.map(item => (
                <div
                  className="hr_search--order__option"
                  onClick={select_option(item)}
                >{fields[item]}</div>
              ))}
            </div>
          </ClickAwayListener>
        ) : null}
      </div>
      <div className="hr_search--add" onClick={onAdd}>
        <img src={plus_icon} className="hr_search--add-icon" />
        <div className="hr_search--add__title">{addTitle}</div>
      </div>
    </div>
  )
}

export default HrSearch
