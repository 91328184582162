import {useState, useEffect} from 'react'
import {enqueueSnackbar} from 'notistack'

import '../css/PositionForm.css'
import {position_categories as api} from 'API/hr'

import {useField} from 'hooks/useField'
import {fields} from 'utils/position.fields'
import {
  Title,
  Field,
  Select,
  Line,
  Button
} from 'components/HrForms'

const PositionForm = ({position, onSave}) => {
  const name = useField('')
  const [categories, set_categories] = useState([])
  const [category, set_category] = useState()
  const [id, set_id] = useState('61d41ae3-ae4e-495c-ba6d-f24e282da4ae')

  useEffect(() => {
    (async () => {
      try {
        const loaded_res = await api
          .get_all()
          .then(res => res.json())
        if (loaded_res.Success) {
          set_categories(loaded_res.Success)
        } else {
          enqueueSnackbar('Invalid query', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    })()
  }, [])

  useEffect(() => {
    if (!position) return
    name.change(position.name)
    set_category(position.category)
  }, [position])

  const on_category_change = category => set_category(category)

  const on_save = () => {
    const errors = []
    errors.push(name.validate_empty())
    if (!category) errors.push(true)
    if (errors.some(err => err)) return
    onSave({
      name: name.value,
      category_id: category.id
    })
  }

  return (
    <>
      <div className="hr_form position_form block-one">
        <Title>{fields.name}</Title>
        <Field
          value={name.value}
          isError={name.is_error}
          onChange={e => name.change(e.target.value)}
        />
      </div>
      <div className="hr_form position_form block-two">
        <Title>{fields.category}</Title>
        <Select
          values={categories}
          value={category}
          onChange={on_category_change}
        />
      </div>
      <div className="hr_form position_form block-three">
        <Title disabled>{fields.id}</Title>
        <Field disabled value={position?.id} />
      </div>
      <Line className="hr_form line" />
      <div className="hr_form position_form block-four">
        <Button className="save_button" onClick={on_save}>SAVE CHANGES</Button>
      </div>
    </>
  )
}

export default PositionForm
