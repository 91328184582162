import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {offices as api} from 'API/hr'
import {fields, order, table} from 'utils/office.fields'
import {HR_EMPTY_VALUE, HR_PAGE_SIZE} from 'utils/constants'

import HrModal from 'components/HrModal'
import Header from '../components/Header'
import HrMenu from '../components/HrMenu'
import HrSearch from '../components/HrSearch'
import HrTable from '../components/HrTable'
import OfficeCard from 'components/OfficeCard'
import HrDialog from 'components/HrDialog'
import HrPagination from 'components/HrPagination'

import 'css/OfficeTable.css'

const process_person = person => {
  if (!person) return ''
  const {
    first_name,
    family_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const process_model = model => {
  const {
    chief_person,
    company,
    department
  } = model
  const res = {...model}
  res.chief_person = process_person(chief_person)
  res.company = model.company.short_name
  res.department = model.department.full_name
  return res
}

const Offices = () => {
  const [models, set_models] = useState()
  const [total, set_total] = useState(0)
  const [page, set_page] = useState(0)
  const [page_size, set_page_size] = useState(HR_PAGE_SIZE)
  const [column, set_column] = useState()
  const [direction, set_direction] = useState('asc')
  const [query, set_query] = useState()

  const [office, set_office] = useState()
  const [remove_office, set_remove_office] = useState()

  const navigate = useNavigate()

  const load_offices = async () => {
    const args = {
      page: {page, page_size},
      order: {column: column || order[0], direction},
      query: query || ''
    }
    try {
      const loaded_res = await api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_models(loaded_res.Success.models.map(model => process_model(model)))
        set_total(loaded_res.Success.total.amount)
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_offices()
  }, [page, page_size, column, direction, query])

  const column_change = column => {
    if (!column) set_column()
    else set_column(column)
  }

  const direction_change = direction => {
    if (!direction) set_direction('asc')
    else (set_direction(direction))
  }

  const query_change = query => {
    if (!query) set_query('')
    else set_query(query)
  }

  const on_add = () => {
    navigate('/hr/office')
  }

  const on_select = async id => {
    try {
      const loaded_res = await api
        .get_by_id(id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_office(loaded_res.Success)
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Invalid department ID', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const on_office_close = () => {
    set_office()
  }

  const on_office_edit = () => {
    navigate(`/hr/office/${office.id}`)
    set_office()
  }

  const on_office_remove = () => {
    set_remove_office(office)
    set_office()
  }

  const on_remove_close = () => {
    set_remove_office()
  }

  const on_remove_aprove = async () => {
    try {
      const remove_res = await api
        .remove(remove_office.id)
        .then(res => res.json())
      if (remove_res.Success) {
        load_offices()
        enqueueSnackbar('Department successfully deleted', {variant: 'success'})
      } else if (remove_res.IsAbsent) {
        enqueueSnackbar('Invalid department ID', {variant: 'error'})
      } else if (remove_res.NotEmpty) {
        enqueueSnackbar('Deletion faild. Related objects found', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_office()
  }

  const on_page_change = value => {
    set_page(value)
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Personnel records</Header>
      <HrMenu />
      <div className="hr_page">
        <HrSearch
          fields={fields}
          order={order}
          column={column}
          onColumnChange={column_change}
          direction={direction}
          onDirectionChange={direction_change}
          query={query}
          onQueryChange={query_change}
          addTitle="ADD A NEW DEPARTMENT"
          onAdd={on_add}
        />
        {models && models.length > 0 ? (
          <>
            <HrTable
              models={models}
              fields={fields}
              table={table}
              select={on_select}
              short={total > page_size}
              className="hr_office"
            />
            {total > page_size ? (
              <HrPagination
                current={page}
                size={page_size}
                total={total}
                change={on_page_change}
              />
            ) : null}
          </>
        ) : (
          <div className="hr_page--empty">{HR_EMPTY_VALUE}</div>
        )}
      </div>
      {office ? (
        <HrModal
          title={office.full_name}
          onClose={on_office_close}
          onEdit={on_office_edit}
          onRemove={on_office_remove}
        >
          <OfficeCard className="hr_modal--card" office={office} />
        </HrModal>
      ) : null}
      {remove_office ? (
        <HrModal
          title="Delete"
          onClose={on_remove_close}
        >
          <HrDialog
            title={`Delete department "${remove_office.full_name}"?`}
            onRemove={on_remove_aprove}
            onCancel={on_remove_close}
          />
        </HrModal>
      ) : null}
    </div>
  )
}

export default Offices
