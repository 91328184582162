import { init as initApm } from '@elastic/apm-rum'

const apm = initApm({
    serviceName: 'frontend',
    serverUrl: process.env.REACT_APP_LOGS_URL,
    environment: process.env.REACT_APP_ENV,
    logLevel: 'info',
})

export default apm
