import {useEffect} from 'react'
import {enqueueSnackbar} from 'notistack'

import '../css/HrDialog.css'
import {department_tasks as api} from 'API/hr'
import {useField} from 'hooks/useField'

const DepartmentTask = (props) => {
  const {
    task,
    onSuccess,
    onCancel
  } = props
  const name = useField('')
  
  useEffect(() => {
    if (!task) return
    name.change(task.name)
  }, [task])

  const on_success = async () => {
    if (name.validate_empty()) return
    const task_to_save = {
      ...task,
      name: name.value
    }
    if (task.id) {
      try {
        const save_res = await api
          .update(task_to_save)
          .then(res => res.json())
        if (save_res.Success) {
          onSuccess(task_to_save)
          enqueueSnackbar('Task successfully saved', {variant: 'success'})
        } else if (save_res.IsAbsent) {
          enqueueSnackbar('Invalid task ID', {variant: 'error'})
        } else if (save_res.IsExistByField) {
          enqueueSnackbar('This task already exists', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid query', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else {
      try {
        const save_res = await api
          .create(task_to_save)
          .then(res => res.json())
        if (save_res.Success) {
          onSuccess(task_to_save)
          enqueueSnackbar('Task successfully saved', {variant: 'success'})
        } else if (save_res.IsExist) {
          enqueueSnackbar('This task already exists', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid query', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    }
  }

  return (
    <div className="hr_dialog">
      <div className="hr_dialog--title">Task editor</div>
      <textarea
        className={`hr_dialog--editor ${name.is_error ? "error" : ""}`}
        value={name.value}
        onChange={e => name.change(e.target.value)}
      />
      <div className="hr_dialog--buttons">
        <button className="hr_dialog--button success" onClick={on_success}>Save</button>
        <button className="hr_dialog--button" onClick={onCancel}>Cancel</button>
      </div>
    </div>
  )
}

export default DepartmentTask
