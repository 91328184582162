export const fields = {
  full_name: 'Department',
  department_id: 'Parental division',
  department: 'Parental division',
  company_id: 'Parental company',
  company: 'Parental company',
  chief_person_id: 'Department manager',
  chief_person: 'Department manager',
  chief_phone: 'Manager’s phone number',
  chief_phone_additional: 'Manager’s phone number (additional)',
  chief_email: 'Manager’s e-mail',
  tasks: 'Department tasks',
  persons: 'Employees'
}

export const order = [
  'full_name',
  'company_id',
  'department_id',
  'chief_person_id',
  'chief_phone',
  'chief_phone_additional',
  'chief_email'
]

export const table = [
  'full_name',
  'department',
  'company',
  'chief_person',
  'chief_phone',
  'chief_phone_additional',
  'chief_email'
]
