import {useState} from 'react'
import Switch from './Switch'

const ProfileRights = () => {
  const [is_ecg_data, set_is_ecg_data] = useState(false)
  const [is_edit, set_is_edit] = useState(false)
  const [is_view, set_is_view] = useState(false)
  const [is_location, set_is_location] = useState(false)
  const [is_email, set_is_email] = useState(false)

  return (
    <div className="profile_container">
      <div className="profile__rights">
        <div className="profile__rights--header">
          <div className="profile__rights--title">Access</div>
          <div className="profile__rights--title">Permission</div>
        </div>
        <div className="profile__rights--body">
          <div className="profile__rights--row">
            <div className="profile__rights--field">ECG data</div>
            <div className="profile__rights--value">
              <Switch on={is_ecg_data} onClick={() => set_is_ecg_data(is => !is)} />
            </div>
          </div>
          <div className="profile__rights--row">
            <div className="profile__rights--field">Personal data editing</div>
            <div className="profile__rights--value">
              <Switch on={is_edit} onClick={() => set_is_edit(is => !is)} />
            </div>
          </div>
          <div className="profile__rights--row">
            <div className="profile__rights--field">Diagnostic results viewing</div>
            <div className="profile__rights--value">
              <Switch on={is_view} onClick={() => set_is_view(is => !is)} />
            </div>
          </div>
          <div className="profile__rights--row">
            <div className="profile__rights--field">Location tracking</div>
            <div className="profile__rights--value">
              <Switch on={is_location} onClick={() => set_is_location(is => !is)} />
            </div>
          </div>
          <div className="profile__rights--row">
            <div className="profile__rights--field">Email notifications</div>
            <div className="profile__rights--value">
              <Switch on={is_email} onClick={() => set_is_email(is => !is)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileRights
