import {useState, useEffect} from 'react'
import {FiCalendar} from 'react-icons/fi';
import ClickAwayListener from 'react-click-away-listener'

import search_icon from 'assets/hr_search.svg'
import remove_icon from 'assets/hr_remove.svg'
import down_icon from 'assets/hr_down.svg'
import edit_icon from 'assets/hr_edit.svg'
import plus_icon from 'assets/hr_plus.svg'
import select_icon from 'assets/hr_select.svg'
import 'css/HrForms.css'

import Calendar from 'components/Calendar'

import {HR_EMPTY_VALUE} from 'utils/constants'

const show_value = value => value ? value : HR_EMPTY_VALUE

export const Title = ({className, children, disabled}) => {
  return (
    <div className={`hr_form--title ${className || ""} ${disabled ? "disabled" : ""}`}>{children}</div>
  )
}

export const Field = ({className, placeholder, isError, value, onChange, disabled, ...props}) => {

  return (
    <div className={`hr_form--field ${className || ""} ${isError ? "error" : ""} ${disabled ? "disabled" : ""}`}>
      {disabled ? (
        <div className="hr_form--disabled_value">{value}</div>
      ) : (
        <input
          className="hr_form--input"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          {...props}
        />
      )}
    </div>
  )
}

export const SearchField = (props) => {
  const [is_open, set_is_open] = useState(false)
  const [query, set_query] = useState('')

  const {
    className,
    isError,
    value,
    values,
    emptyValue,
    onSearch,
    onChange,
    thik
  } = props

  useEffect(() => {
    if (!is_open) return
    set_query('')
    onSearch()
  }, [is_open])

  const open_options = e => {
    e.stopPropagation()
    set_is_open(is_open => !is_open)
  }

  const away_options = () => {
    set_is_open(false)
  }

  const select_option = option => e => {
    e.stopPropagation()
    onChange(option)
    set_is_open(false)
  }

  const on_query_change = e => {
    const {value} = e.target
    set_query(value)
    onSearch(value)
  }

  return (
    <div
      className={`hr_form--search_field ${className || ""} ${isError ? "hr_error" : ""} ${thik ? "thik" : ""}`}
      onClick={open_options}
    >
      <div className="hr_form--search_value" >{value ? value.name : HR_EMPTY_VALUE}</div>
      <img src={search_icon} className="hr_form--search_icon" />
      {is_open ? (
        <ClickAwayListener onClickAway={away_options}>
          <div className={`hr_form--search_field__options ${thik ? "thik" : ""}`}>
            <input
              className="hr_form--search_input"
              autoFocus
              value={query}
              onChange={on_query_change}
            />
            {emptyValue ? (
              <div
                className="hr_form--search_field__option"
                onClick={select_option()}
              >{HR_EMPTY_VALUE}</div>
            ) : null}
            {values.map(item => (
              <div
                className="hr_form--search_field__option"
                onClick={select_option(item)}
              >{item.name}</div>
            ))}
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  )
}

export const List = (props) => {
  const {
    className,
    values,
    onAdd,
    onSelect,
    onEdit,
    onRemove
  } = props

  const on_select = () => {
    if (onSelect) onSelect()
    else onAdd()
  }

  return (
    <div className={`hr_form--list ${className || ""}`}>
      <div className={`hr_form--list__container`}>
        {values ? values.map(value => (
          <div className="hr_form--list_item">
            <div className="hr_form--list_item--block">
              <div className="hr_form--list_item--text">{value.name}</div>
              {onRemove ? (
                <img
                  src={remove_icon}
                  className="hr_form--list_item--remove"
                  onClick={() => onRemove(value)}
                />
              ) : null}
            </div>
            <img
              src={edit_icon}
              className="hr_form--list_item--edit"
              onClick={() => onEdit(value)}
            />
          </div>
        )) : null}
        <div className="hr_form--list_item">
          <div className="hr_form--list_item--block_add" onClick={on_select}></div>
          <img src={plus_icon} className="hr_form--list_item--plus" onClick={onAdd} />
        </div>
      </div>
    </div>
  )
}

export const Line = ({className}) => {
  return (
    <div className={`hr_form--line ${className || ""}`}></div>
  )
}

export const Button = ({className, children, onClick}) => {
  return (
    <button
      className={`hr_form--button ${className || ""}`}
      onClick={onClick}
    >{children}</button>
  )
}

export const Select = (props) => {
  const [is_open, set_is_open] = useState(false)

  const {
    className,
    isError,
    value,
    values,
    onChange,
    disabled,
    emptyValue
  } = props

  const show_select_value = value => value ? value : (emptyValue || HR_EMPTY_VALUE)

  const open_options = () => {
    set_is_open(is_open => !is_open)
  }

  const away_options = () => {
    set_is_open(false)
  }

  const select_option = option => e => {
    e.stopPropagation()
    onChange(option)
    set_is_open(false)
  }

  return (
    <div
      className={`hr_form--select ${className || ""} ${isError ? "error" : ""} ${disabled ? "disabled" : ""}`}
      onClick={open_options}
    >
      <div
        className={`hr_form--select_value ${disabled ? "disabled" : ""}`}
      >{show_select_value(value?.name)}</div>
      <img
        src={select_icon}
        className="hr_form--select_icon"
      />
      {is_open ? (
        <ClickAwayListener onClickAway={away_options}>
          <div className="hr_form--select__options">
            {values.map(item => (
              <div
                className="hr_form--select__option"
                onClick={select_option(item)}
              >{item.name}</div>
            ))}
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  )
}

export const DatePicker = (props) => {
  const [is_open, set_is_open] = useState(false)

  const {
    className,
    isError,
    value,
    onChange,
    disabled,
    emptyValue
  } = props

  const show_date = date => {
    if (!date || date === '') return emptyValue || HR_EMPTY_VALUE
    const year = date.getFullYear()
    const month_dirty = date.getMonth() + 1
    const month = month_dirty > 9 ? month_dirty : `0${month_dirty}`
    const date_dirty = date.getDate()
    const date_clear = date_dirty > 9 ? date_dirty : `0${date_dirty}`
    return `${date_clear}.${month}.${year}`
  }

  const open_calendar = () => {
    set_is_open(true)
  }

  const away_calendar = () => {
    set_is_open(false)
  }

  const select_date = date => {
    onChange(date)
    set_is_open(false)
  }


  return (
    <div className={`hr_form--datepicker ${className || ""} ${isError ? "error" : ""} ${disabled ? "disabled" : ""}`}>
      <div className="hr_form--datepicker__container" onClick={open_calendar}>
        <div className={`hr_form--datepicker_value ${disabled ? "disabled" : ""}`}>{show_date(value)}</div>
        <FiCalendar color='#3D7CB6' size='20px' cursor={'pointer'}></FiCalendar>
      </div>
      {is_open ? (
        <ClickAwayListener onClickAway={away_calendar}>
          <div className="hr_form--datepicker__options">
            <Calendar
              selectDate={value || new Date()}
              setSelectDate={select_date}
              handleClick={away_calendar}
              fullYears
            />
          </div>
        </ClickAwayListener>
      ) : null}
    </div>

  )
}
