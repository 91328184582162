import '../css/CompanyCard.css'

import {fields} from 'utils/company.fields'
import {HR_EMPTY_VALUE} from 'utils/constants'

const into_chunks = arr => {
  const res = [[], [], []]
  if (!arr || arr.length === 0) {
    res[0].push(HR_EMPTY_VALUE)
    return res
  }
  for (let i = 0; i < arr.length; i++) {
    res[i % 3].push(arr[i].full_name)
  }
  return res
}

const show_person = person => {
  if (!person) return HR_EMPTY_VALUE
  const {
    first_name,
    family_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const show_position = position => {
  if (!position) return HR_EMPTY_VALUE
  return position.name
}

const show_field = value => value ? value : HR_EMPTY_VALUE

const CompanyCard = ({className, company}) => {

  const department_chunks = into_chunks(company?.departments)

  return (
    <div className={`hr_company-card ${className}`}>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.legal_address}</div>
        <div className="hr_company-card__field--value">{show_field(company?.legal_address)}</div>
      </div>
      <div className="hr_company-card__sub_double">
        {/* <div className="hr_company-card__field">
          <div className="hr_company-card__field--title">{fields.inn}</div>
          <div className="hr_company-card__field--value">{show_field(company?.inn)}</div>
        </div>
        <div className="hr_company-card__field">
          <div className="hr_company-card__field--title">{fields.ogrn}</div>
          <div className="hr_company-card__field--value">{show_field(company?.ogrn)}</div>
        </div> */}
      </div>
      <div className="hr_company-card__sub_triple">
        {/* <div className="hr_company-card__field">
          <div className="hr_company-card__field--title">{fields.kpp}</div>
          <div className="hr_company-card__field--value">{show_field(company?.kpp)}</div>
        </div>
        <div className="hr_company-card__field">
          <div className="hr_company-card__field--title">{fields.okpo}</div>
          <div className="hr_company-card__field--value">{show_field(company?.okpo)}</div>
        </div>
        <div className="hr_company-card__field">
          <div className="hr_company-card__field--title">{fields.okved}</div>
          <div className="hr_company-card__field--value">{show_field(company?.okved)}</div>
        </div> */}
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.actual_address}</div>
        <div className="hr_company-card__field--value">{show_field(company?.actual_address)}</div>
      </div>
      <div className="hr_company-card__sub_double">
        <div className="hr_company-card__field">
          <div className="hr_company-card__field--title">{fields.phone}</div>
          <div className="hr_company-card__field--value">{show_field(company?.phone)}</div>
        </div>
        <div className="hr_company-card__field">
          <div className="hr_company-card__field--title">{fields.phone_additional}</div>
          <div className="hr_company-card__field--value">{show_field(company?.phone_additional)}</div>
        </div>
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.email}</div>
        <div className="hr_company-card__field--value">{show_field(company?.email)}</div>
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.chief_person}</div>
        <div className="hr_company-card__field--value">{show_person(company?.chief_person)}</div>
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.chief_position}</div>
        <div className="hr_company-card__field--value">{show_position(company?.chief_position)}</div>
      </div>
      <div className="hr_company-card__field"></div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.hr_person}</div>
        <div className="hr_company-card__field--value">{show_person(company?.hr_person)}</div>
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.admin_person}</div>
        <div className="hr_company-card__field--value">{show_person(company?.admin_person)}</div>
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.parent_organization}</div>
        <div className="hr_company-card__field--value">{show_field(show_field())}</div>
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--title">{fields.departments}</div>
        <div className="hr_company-card__field--list">
          {department_chunks[0].map((department, i)=> (
            <div key={i} className="hr_company-card__field--value">{department}</div>
          ))}
        </div>
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--list">
          {department_chunks[1].map((department, i) => (
            <div key={i} className="hr_company-card__field--value">{department}</div>
          ))}
        </div>
      </div>
      <div className="hr_company-card__field">
        <div className="hr_company-card__field--list">
          {department_chunks[2].map((department, i) => (
            <div key={i} className="hr_company-card__field--value">{department}</div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CompanyCard
