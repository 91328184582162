import '../css/HrTable.css'

const HrTable = (props) => {
  const {
    models,
    fields,
    table,
    select,
    short,
    className
  } = props

  return (
    <>
      <div className={`hr_table ${short ? 'short' : ''} ${className}`}>
        <table className="hr_table--root">
          <thead>
            <tr>
              {table.map((table_field, i) => (
                <th key={i}>{fields[table_field]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {models.map(model => (
              <tr key={model.id} onClick={() => select(model.id)}>
                {table.map((table_field, i) => (
                  <td key={i}>{model[table_field]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default HrTable
