import React, { useState, useEffect, useContext, useRef } from 'react'

import 'css/EcgHistory.css'

import DataService from 'API/DataService'
import { EcgTrend } from 'components/EcgTrend'
import { EcgChannel } from 'components/EcgChannel'

import date_left_svg from 'assets/date_left.svg'
import date_left_active_svg from 'assets/date_left_active.svg'
import date_right_svg from 'assets/date_right.svg'
import date_right_active_svg from 'assets/date_right_active.svg'
import loupe_svg from 'assets/loupe.svg'
import plus_scale_active_svg from 'assets/plus_scale_active.svg'
import plus_scale_svg from 'assets/plus_scale.svg'
import minus_scale_active_svg from 'assets/minus_scale_active.svg'
import minus_scale_svg from 'assets/minus_scale.svg'
import eye_svg from 'assets/eye.svg'
import waves_icon from 'assets/waves.svg'
import Filter from 'components/Filter'
import { BUILD_CHART_EVENTS, DIAGNOSTICS_FILTERS } from 'utils/constants'
import { getEndDate, getStartDate } from 'utils/date-utils'
import { useOutletContext } from 'react-router-dom'
import { useFetching } from 'hooks/useFetching'
import { getDayMonthFullYear } from 'utils/date-utils'
import Switch from 'components/Switch'

const scale_to_seconds = scale => {
  if (scale === 0) return 2
  if (scale === 1) return 5
  if (scale === 2) return 10
  if (scale === 3) return 20
  if (scale === 4) return 30
  return 0
}

const get_up = scale => scale > 0 ? plus_scale_active_svg : plus_scale_svg
const get_down = scale => scale < 4 ? minus_scale_active_svg : minus_scale_svg

const DPS = 250
const MV = 1000

const get_shift = scale => {
  if (scale === 0) return 1
  if (scale === 1) return 2.5
  if (scale === 2) return 5
  if (scale === 3) return 10
  if (scale === 4) return 15
  return 0
}

const process = (points, current) => {
  let first_acc = []
  let second_acc = []
  let third_acc = []
  const total_amount = points.firstChannel.length
  const point_size = 1000 / DPS
  for (let counter = 0; counter < total_amount; counter++) {
    const point_time = current.getTime() + counter * point_size
    first_acc.push({
      value: points.firstChannel[counter] / MV,
      time: new Date(point_time)
    })
    second_acc.push({
      value: points.secondChannel[counter] / MV,
      time: new Date(point_time)
    })
    third_acc.push({
      value: points.thirdChannel[counter] / MV,
      time: new Date(point_time)
    })
  }
  return ({
    first_acc,
    second_acc,
    third_acc
  })
}

const showPQRSTMetrics = value => value ?? '—'

const EcgHistory = () => {
  const {
    setStartDate: setCalendarDate,
    setTimeDate: setCalendarTime,
    chartDate: buildDate,
    setChartDate: setBuildDate,
    chartShift: slider_value,
    setChartShift: set_slider_value
  } = useOutletContext()

  const [points, set_points] = useState([])
  const [ranges, set_ranges] = useState([])
  const [scale, set_scale] = useState(2)
  const [first_channel, set_first_channel] = useState([])
  const [second_channel, set_second_channel] = useState([])
  const [third_channel, set_third_channel] = useState([])
  const [channel_ranges, set_channel_ranges] = useState([])
  const [show_channels, set_show_channels] = useState([true, true, true])
  const [time_axis, set_time_axis] = useState([false, false, true])
  const [slider_max, set_slider_max] = useState()
  const [slider_step, set_slider_step] = useState()
  const [filter_clicked, set_filter_clicked] = useState(0)
  const [is_waves, set_is_waves] = useState(false)
  const [PQRSTMetrics, setPQRSTMetrics] = useState([])
  const [ecg_cursor, set_ecg_cursor] = useState()

  const firstRender = useRef(true)

  const calculate_current_date = () => {
    if (buildDate && buildDate.date) {
      let new_date = new Date(buildDate.date.getTime())
      new_date.setHours(0, 0, 0, 0)
      return new_date
    }
    else {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      return today
    }
  }

  const calculate_cursor = () => {
    if (buildDate && buildDate.date) {
      return new Date(buildDate.date.getTime())
    }
    else {
      return new Date()
    }
  }

  const set_cursor = (cursor) => {
    setCalendarTime(cursor)
    setBuildDate({
      date: cursor,
      event: BUILD_CHART_EVENTS.cursor
    })
  }

  const current_date = calculate_current_date()
  const cursor = calculate_cursor()

  const getPQRSTMetrics = () => {
    if (!ecg_cursor || !PQRSTMetrics?.length) return
    if (ecg_cursor < new Date(PQRSTMetrics[0].loc_R_Pick_Timestamp)) return
    let length = PQRSTMetrics.length
    if (ecg_cursor >= new Date(PQRSTMetrics[length - 1].loc_R_Pick_Timestamp))
      return PQRSTMetrics[length - 1]
    for (let i = 0; i < length - 1; i++) {
      let first_timestamp = new Date(PQRSTMetrics[i].loc_R_Pick_Timestamp)
      let second_timestamp = new Date(PQRSTMetrics[i + 1].loc_R_Pick_Timestamp)
      if (ecg_cursor >= first_timestamp && ecg_cursor < second_timestamp)
        return PQRSTMetrics[i]
    }
  }

  const current_PQRSTMetric = getPQRSTMetrics()

  const [fetchAnalysis, analysisLoading, analysisError] = useFetching(async () => {
    const responseData = await DataService.getAnalysis(cursor.toISOString(), 10)
    if (!responseData) return
    //console.log(responseData)
    set_channel_ranges(responseData)
  })

  const [fetchAnalysisShort, analysisShortLoading, analysisShrotError] = useFetching(async () => {
    const responseData = await DataService.getAnalysisShort(current_date.toISOString(), 1440)
    if (!responseData) return
    let filterRanges = []
    if (responseData.length) {
      filterRanges.push(responseData[0])
      let idx = 0
      for (let i = 1; i < responseData.length; i++) {
        const prevDate = new Date(responseData[idx].timestamp)
        const curDate = new Date(responseData[i].timestamp)
        if ((curDate - prevDate) / (1000 * 60) < 1) continue
        idx = i
        filterRanges.push({ timestamp: curDate, numPathology: responseData[i].numPathology })
      }
    }
    set_ranges(filterRanges)
  })

  const [fetchTrendData, trendLoading, trendError] = useFetching(async () => {
    const responseData = await DataService.getRateTrendData(1440, current_date.toISOString())
    if (!responseData) return
    let trendPoints = []
    for (let i = 0; i < responseData.length; i++) {
      trendPoints.push({
        value: responseData[i],
        time: new Date(current_date.getTime() + i * 1000 * 60)
      })
    }
    set_points(trendPoints)
  })

  const [fetchChennelsData, channelsDataLoading, channelsDataError] = useFetching(async () => {
    if (!cursor) return
    const end_day = getEndDate(current_date)
    let minutes = Math.floor((end_day.getTime() + 1000 - cursor.getTime()) / 1000 / 60)
    if (minutes <= 0) return
    if (minutes > 10)
      minutes = 10
    const filter = DIAGNOSTICS_FILTERS[filter_clicked]
    await build_points(cursor, minutes, filter.lowFrequency, filter.highFrequency, filter.flag)
  })

  const [fetchPQRSTMetrics, PQRSTMetricsLoading, PQRSTMetricsError] = useFetching(async () => {
    const responseData = await DataService.getPQRSTMetrics(cursor.toISOString(), 10)
    if (!responseData) return
    //console.log(responseData)
    setPQRSTMetrics(responseData)
  })

  useEffect(() => {
    (async () => {
      if (!buildDate) return
      if (buildDate.event !== BUILD_CHART_EVENTS.cursor || !points?.length) {
        await fetchTrendData()
        await fetchAnalysisShort()
      }
      await fetchChennelsData()
      await fetchAnalysis()
      await fetchPQRSTMetrics()
      set_ecg_cursor()
      if (points?.length)
        set_slider_value(0)
    })()
  }, [buildDate])

  const build_points = async (build_data, minutes, lowFrequency, highFrequency, flag) => {
    const points = await DataService.getEcgData(build_data, minutes, lowFrequency, highFrequency, flag)
    if (!points.firstChannel) return
    const {
      first_acc,
      second_acc,
      third_acc
    } = process(points, build_data)
    set_first_channel(first_acc)
    set_second_channel(second_acc)
    set_third_channel(third_acc)
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else if (!trendError) {
      fetchChennelsData()
    }
  }, [filter_clicked])

  useEffect(() => {
    const points = first_channel
    if (!points || !points.length) return
    const max = points.length - scale_to_seconds(scale) * DPS
    const step = DPS * get_shift(scale)
    let diff = 0
    if (slider_step) {
      diff = (slider_step - step) * 2
    }
    set_slider_max(max)
    set_slider_step(step)
    set_slider_value(v => {
      if (v)
        return v + diff
      else
        return v
    })
  }, [first_channel, scale])

  const is_next_date = () => {
    if (channelsDataLoading || trendLoading) return false
    const today = new Date()
    if (
      current_date.getDate() === today.getDate() &&
      current_date.getMonth() === today.getMonth() &&
      current_date.getFullYear() === today.getFullYear()
    ) return false
    return true
  }

  const to_prev = () => {
    if (channelsDataLoading || trendLoading) return
    const prev_date = new Date(current_date.getTime())
    const dom = current_date.getDate()
    prev_date.setDate(dom - 1)
    let newBuildDate = new Date(prev_date.getTime())
    newBuildDate.setHours(buildDate.date.getHours(), buildDate.date.getMinutes(), buildDate.date.getSeconds())
    setCalendarDate(prev_date)
    setBuildDate({
      date: newBuildDate,
      event: BUILD_CHART_EVENTS.arrow
    })
  }

  const to_next = () => {
    if (!is_next_date()) return
    const next_date = new Date(current_date.getTime())
    const dom = current_date.getDate()
    next_date.setDate(dom + 1)
    let newBuildDate = new Date(next_date.getTime())
    newBuildDate.setHours(buildDate.date.getHours(), buildDate.date.getMinutes(), buildDate.date.getSeconds())
    setCalendarDate(next_date)
    setBuildDate({
      date: newBuildDate,
      event: BUILD_CHART_EVENTS.arrow
    })
  }

  const scale_up = () => {
    if (scale <= 0) return
    set_scale(value => value - 1)
  }

  const scale_down = () => {
    if (scale >= 4) return
    set_scale(value => value + 1)
  }

  const get_points = channel => {
    const end = slider_value + scale_to_seconds(scale) * DPS
    const points = channel.slice(slider_value, end)
    if (points.length === scale_to_seconds(scale) * DPS)
      return points
  }

  const show_ch_clicked = (index) => {
    let active_count = 0;
    for (let i = 0; i < show_channels.length; i++) {
      if (i === index) continue;
      if (show_channels[i])
        active_count++;
    }
    if (active_count) {
      const new_show_channels = show_channels.map((ch, i) => {
        if (i === index)
          return !ch;
        else
          return ch;
      });
      let new_time_axis = [false, false, false];
      for (let i = new_show_channels.length - 1; i >= 0; i--) {
        if (new_show_channels[i]) {
          new_time_axis[i] = true;
          break;
        }
      }
      set_show_channels(new_show_channels);
      set_time_axis(new_time_axis);
    }
  }

  const is_next_ten_min = () => {
    if (!first_channel.length || channelsDataLoading || trendLoading ||
      !first_channel.at(slider_value)) return false;
    const end_day = getEndDate(current_date);
    let start_channel = new Date(first_channel.at(slider_value).time);
    start_channel.setMilliseconds(0);
    start_channel.setSeconds(0);
    const next_ten_min = new Date(start_channel.getTime() + 10 * 60 * 1000);
    return next_ten_min < end_day;
  }

  const is_prev_ten_min = () => {
    if (!first_channel.length || channelsDataLoading || trendLoading ||
      !first_channel.at(slider_value)) return false;
    const start_day = getStartDate(current_date);
    return first_channel.at(slider_value).time > start_day;
  }

  const load_next_ten_min = () => {
    if (!is_next_ten_min()) return;
    let start_channel = new Date(first_channel.at(slider_value).time);
    start_channel.setMilliseconds(0);
    start_channel.setSeconds(0);
    const new_cursor = new Date(start_channel.getTime() + 10 * 60 * 1000);
    set_cursor(new_cursor);
  }

  const load_prev_ten_min = () => {
    if (!is_prev_ten_min()) return;
    let new_cursor;
    const start_day = getStartDate(current_date);
    let start_channel = new Date(first_channel.at(slider_value).time);
    start_channel.setMilliseconds(0);
    start_channel.setSeconds(0);
    const minutes = Math.floor((start_channel.getTime() + 1000 - start_day.getTime()) / 1000 / 60);
    if (minutes <= 0)
      new_cursor = start_day;
    else if (minutes > 10)
      new_cursor = new Date(start_channel.getTime() - 10 * 60 * 1000);
    else
      new_cursor = new Date(start_channel.getTime() - minutes * 60 * 1000);
    set_cursor(new_cursor);
  }

  const slider_change = (e) => {
    set_slider_value(+e.target.value)
  }

  const get_channel_isoline_color = (channel) => {
    return channel.every(p => p.value === 0) ? '#a3a3a3' : '#4BB56F'
  }

  return (
    <div className="EcgHistory">
      <div className="ecg-header">
        <div className="title">Heart rate trend</div>
        <div className="date-picker">
          <img className="icon" src={(!channelsDataLoading && !trendLoading) ? date_left_active_svg : date_left_svg} onClick={to_prev} />
          <div className="date">{getDayMonthFullYear(current_date)}</div>
          <img className="icon" src={is_next_date() ? date_right_active_svg : date_right_svg} onClick={to_next} />
        </div>
      </div>
      <EcgTrend
        points={points}
        ranges={ranges}
        cursor={cursor}
        setCursor={set_cursor}
        loading={trendLoading}
      />
      <div className="ecg-history__tools">
        <div className='ecg-scales'>
          <img className='ecg-loupe' src={loupe_svg} />
          <img className='ecg-plus' src={get_up(scale)} onClick={scale_up} />
          <img className="ecg-minus" src={get_down(scale)} onClick={scale_down} />
        </div>
        <div className='ecg-show-channels'>
          <img className='ecg-eye' src={eye_svg} />
          <div className={show_channels[0] ? 'ecg-show active' : 'ecg-show'}
            onClick={() => show_ch_clicked(0)}>
            1 ch
          </div>
          <div className={show_channels[1] ? 'ecg-show active' : 'ecg-show'}
            onClick={() => show_ch_clicked(1)}>
            2 ch
          </div>
          <div className={show_channels[2] ? 'ecg-show active' : 'ecg-show'}
            onClick={() => show_ch_clicked(2)}>
            3 ch
          </div>
        </div>
        <div className='ecg-slider'>
          <img className="icon" src={is_prev_ten_min() ? date_left_active_svg : date_left_svg}
            onClick={load_prev_ten_min} />
          <input type='range'
            className='slider'
            min={0}
            max={slider_max}
            step={slider_step}
            value={slider_value}
            onChange={slider_change} />
          <img className="icon" src={is_next_ten_min() ? date_right_active_svg : date_right_svg}
            onClick={load_next_ten_min} />
        </div>
        <div className='ecg-filters'>
          <Filter filters={DIAGNOSTICS_FILTERS}
            clicked={filter_clicked}
            setClicked={set_filter_clicked} />
        </div>
        <div className='ecg-switch waves'>
          <img className='waves-icon' src={waves_icon} />
          <Switch on={is_waves} onClick={() => set_is_waves(is => !is)} />
        </div>
      </div>
      <div className='ecg-waves'>
        {
          is_waves && (
            <div className='ecg-waves__container'>
              <div className='ecg-waves__left'>
                <div className='ecg-waves__column-base'>
                  <div className='ecg-waves__title'>Amplitude:</div>
                  <div className='ecg-waves__column'>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>1 CH:</div>
                      <div className='ecg-waves__value'>—</div>
                      <div className='ecg-waves__aux'>mV</div>
                    </div>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>2 CH:</div>
                      <div className='ecg-waves__value'>—</div>
                      <div className='ecg-waves__aux'>mV</div>
                    </div>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>3 CH:</div>
                      <div className='ecg-waves__value'>—</div>
                      <div className='ecg-waves__aux'>mV</div>
                    </div>
                  </div>
                </div>
                <div className='ecg-waves__column-base '>
                  <div className='ecg-waves__title'>Duration:</div>
                  <div className='ecg-waves__column'>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>Interval:</div>
                      <div className='ecg-waves__value blue'>—</div>
                      <div className='ecg-waves__aux'>mV</div>
                    </div>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>Parametr2:</div>
                      <div className='ecg-waves__value'>—</div>
                      <div className='ecg-waves__aux'>PA</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='ecg-waves__right'>
                <div className='ecg-waves__column-base'>
                  <div className='ecg-waves__title'>Intervals:</div>
                  <div className='ecg-waves__column'>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>R-R:</div>
                      <div className='ecg-waves__value'>{showPQRSTMetrics(current_PQRSTMetric?.rrIntervalLen)}</div>
                      <div className='ecg-waves__aux'>ms</div>
                    </div>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>P-Q:</div>
                      <div className='ecg-waves__value'>{showPQRSTMetrics(current_PQRSTMetric?.pqIntervalLen)}</div>
                      <div className='ecg-waves__aux'>ms</div>
                    </div>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>Q-T</div>
                      <div className='ecg-waves__value'>{showPQRSTMetrics(current_PQRSTMetric?.qtIntervalLen)}</div>
                      <div className='ecg-waves__aux'>ms</div>
                    </div>
                    <div className='ecg-waves__row'>
                      <div className='ecg-waves__field'>S-T</div>
                      <div className='ecg-waves__value'>{showPQRSTMetrics(current_PQRSTMetric?.stIntervalLen)}</div>
                      <div className='ecg-waves__aux'>ms</div>
                    </div>
                  </div>
                </div>
                <div className='ecg-waves__column-base'>
                  <div className='ecg-waves__title'>Segments:</div>
                  <div className='ecg-waves__row'>
                    <div className='ecg-waves__field'>P-Q:</div>
                    <div className='ecg-waves__value'>{showPQRSTMetrics(current_PQRSTMetric?.pqSeqmentLen)}</div>
                    <div className='ecg-waves__aux'>ms</div>
                  </div>
                </div>
                <div className='ecg-waves__column-base'>
                  <div className='ecg-waves__title'>Waves:</div>
                  <div className='ecg-waves__row'>
                    <div className='ecg-waves__field'>P:</div>
                    <div className='ecg-waves__value'>{showPQRSTMetrics(current_PQRSTMetric?.pWaveLen)}</div>
                    <div className='ecg-waves__aux'>ms</div>
                  </div>
                  <div className='ecg-waves__row'>
                    <div className='ecg-waves__field'>QRS:</div>
                    <div className='ecg-waves__value'>{showPQRSTMetrics(current_PQRSTMetric?.qrsComplexLen)}</div>
                    <div className='ecg-waves__aux'>ms</div>
                  </div>
                  <div className='ecg-waves__row'>
                    <div className='ecg-waves__field'>T:</div>
                    <div className='ecg-waves__value'>{showPQRSTMetrics(current_PQRSTMetric?.tWaveLen)}</div>
                    <div className='ecg-waves__aux'>ms</div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className="ecg-history__ecg-channels">
        {
          show_channels[0] && (
            <EcgChannel
              points={get_points(first_channel)}
              ranges={channel_ranges}
              scale={scale}
              isTimeAxis={time_axis[0]}
              ch={1}
              isolineColor={get_channel_isoline_color(first_channel)}
              cursor={ecg_cursor}
              setCursor={set_ecg_cursor} />
          )
        }
        {
          show_channels[1] && (
            <EcgChannel
              points={get_points(second_channel)}
              ranges={channel_ranges}
              scale={scale}
              isTimeAxis={time_axis[1]}
              ch={2}
              isolineColor={get_channel_isoline_color(second_channel)}
              cursor={ecg_cursor}
              setCursor={set_ecg_cursor} />
          )
        }
        {
          show_channels[2] && (
            <EcgChannel
              points={get_points(third_channel)}
              ranges={channel_ranges}
              scale={scale}
              isTimeAxis={time_axis[2]}
              ch={3}
              isolineColor={get_channel_isoline_color(second_channel)}
              cursor={ecg_cursor}
              setCursor={set_ecg_cursor} />
          )
        }
      </div>
    </div>
  )
}

export default EcgHistory
