import {useState, useEffect} from 'react'

import Menu from 'components/Menu'
import Header from 'components/Header'
import ProfileCard from 'components/ProfileCard'
import ProfileAuth from 'components/ProfileAuth'
import ProfileContacts from 'components/ProfileContacts'
import ProfileDevices from 'components/ProfileDevices'
import ProfilePersonal from 'components/ProfilePersonal'
import ProfileRights from 'components/ProfileRights'
import DataService from 'API/DataService'

import 'css/ProfilePage.css'
import { useOutletContext } from 'react-router-dom'

const Profile = () => {
  const [current, set_current] = useState(0)
  const {profile, on_reload} = useOutletContext()

  const item_cls = idx =>
    `profile_menu--item${current === idx ? ' active' : ''}`

  const to_tab = idx => () => set_current(idx)

  return <>
    {/* <Menu/> */}
    <div className="layout-page profile_layout">
      <Header withoutBottom>Profile edit</Header>
      {profile ? (
        <>
          <div className="profile_menu">
            <div className={item_cls(0)} onClick={to_tab(0)}>Personal information</div>
            <div className={item_cls(1)} onClick={to_tab(1)}>Contacts</div>
            <div className={item_cls(2)} onClick={to_tab(2)}>Security</div>
            <div className={item_cls(3)} onClick={to_tab(3)}>Devices</div>
            <div className={item_cls(4)} onClick={to_tab(4)}>Access permissions</div>
          </div>
          <div className="profile_page">
            <ProfileCard profile={profile} />
            {current === 0 ? <ProfilePersonal profile={profile} onReload={on_reload} /> : null}
            {current === 1 ? <ProfileContacts profile={profile} onReload={on_reload} /> : null}
            {current === 2 ? <ProfileAuth profile={profile} onReload={on_reload} toTab={to_tab(0)} /> : null}
            {current === 3 ? <ProfileDevices profile={profile} onReload={on_reload} /> : null}
            {current === 4 ? <ProfileRights profile={profile} onReload={on_reload} /> : null}
          </div>
        </>
      ) : null}
    </div>
  </>
}

export default Profile
