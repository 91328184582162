import { useState, useRef, useEffect } from 'react'
import { useOutsideClick } from 'hooks/useOutsideClick'

import { lead_zero } from 'utils/functions'
import select_icon from 'assets/time_arrow.svg'

import '../css/SelectTime.css'

const hs = [...Array(24).keys()].map(val => lead_zero(val))
const ms = [...Array(60).keys()].map(val => lead_zero(val))

const SelectTime = ({ time, setTime, minutes, hlHours, ...props }) => {
    const [selectTime, setSelectTime] = useState('00')

    const selectRef = useRef()

    const [isSelectActive, setIsSelectActive] = useOutsideClick(selectRef, false)

    useEffect(() => {
        let timeValue = minutes ? (time ? time.getMinutes() : 0) :
            (time ? time.getHours() : 0)
        timeValue = lead_zero(timeValue)
        setSelectTime(timeValue)
    }, [time])

    const handleSelectClick = () => {
        setIsSelectActive(!isSelectActive)
    };

    const handleOptionClick = (e, t) => {
        const new_date = new Date(time.getTime())
        minutes ? new_date.setMinutes(parseInt(t)) : new_date.setHours(parseInt(t))
        setTime(new_date)
    }

    const handleInputChange = (e) => {
        let value = e.target.value
        value = value.replace(/[^0-9]/g, '')
        value = value.substr(0, 2)
        setSelectTime(value)
    }

    const handeleInputBlur = (e) => {
        let value = parseInt(e.target.value)
        if (!value) value = 0
        else if (value > maxTime) value = maxTime
        const new_date = new Date(time.getTime())
        minutes ? new_date.setMinutes(value) : new_date.setHours(value)
        setTime(new_date)
    }

    const options = minutes ? ms : hs
    const maxTime = minutes ? 59 : 23

    return (
        <div className='select-time'>
            <input className='time__input'
                value={selectTime}
                onChange={handleInputChange}
                onBlur={handeleInputBlur}
                {...props} />
            <div className='time__select' ref={selectRef} onClick={handleSelectClick}>
                <img src={select_icon} className="select__icon" />
                {
                    isSelectActive && (
                        <div className='select__options'>
                            {options.map(t => {
                                const isHl = hlHours?.includes(t)
                                return (<div className={`select__option ${isHl ? 'hl' : ''}`} key={`hour_${t}`} onClick={e => handleOptionClick(e, t)}>
                                    {t}
                                </div>)
                            })}
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default SelectTime;