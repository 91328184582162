export const fields = {
  name: 'Business name',
  short_name: 'Краткое наименование организации',
  legal_address: 'Business address',
  actual_address: 'Operating address',
  inn: 'VAT identification number',
  ogrn: 'Registration number',
  kpp: 'КПП',
  okpo: 'ОКПО',
  okved: 'ОКВЭД',
  chief_position: 'Manager’s position ',
  chief_position_id: 'Manager’s position ',
  chief_person: 'Manager’s name',
  chief_person_id: 'Manager’s name',
  parent_organization: 'Parental company',
  phone: 'Phone number',
  phone_additional: 'Phone number (additional)',
  email: 'E-mail',
  admin_person: 'System administrator',
  admin_person_id: 'System administrator',
  hr_person: 'HR officer',
  hr_person_id: 'HR officer',
  departments: 'Divisions'
}

export const order = [
  'name',
  'legal_address',
  'actual_address',
  'chief_position_id',
  'chief_person_id',
  'parent_organization',
  'phone',
  'phone_additional',
  'email',
  'admin_person_id',
  'hr_person_id'
]

export const table = [
  'name',
  'legal_address',
  'actual_address',
  'chief_position',
  'chief_person',
  'parent_organization',
  'phone',
  'phone_additional',
  'email',
  'admin_person',
  'hr_person'
]
