import {Outlet, useOutletContext} from 'react-router-dom'

const Hr = () => {
  const {
    profile,
    is_avatar_exist
  } = useOutletContext()

  return <>
    <Outlet context={{profile, is_avatar_exist}}/>
  </>
}

export default Hr
