export const fields = {
  full_name: 'Division',
  company_id: 'Parental company',
  company: 'Parental company',
  chief_person_id: 'Division manager',
  chief_person: 'Division manager',
  chief_phone: 'Manager’s phone number',
  chief_phone_additional: 'Manager’s phone number (additional)',
  chief_email: 'Manager’s e-mail',
  tasks: 'Divison tasks',
  offices: 'List of the departments'
}

export const order = [
  'full_name',
  'company_id',
  'chief_person_id',
  'chief_phone',
  'chief_phone_additional',
  'chief_email'
]

export const table = [
  'full_name',
  'company',
  'chief_person',
  'chief_phone',
  'chief_phone_additional',
  'chief_email'
]
