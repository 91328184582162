import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import '../css/PersonForm.css'
import {
  positions as positions_api,
  offices as offices_api,
  devices as devices_api
} from 'API/hr'
import HrModal from 'components/HrModal'
import HrDialog from 'components/HrDialog'
import DeviceSelect from 'components/DeviceSelect'

import {useField} from 'hooks/useField'
import {fields} from 'utils/person.fields'
import {
  Title,
  Field,
  SearchField,
  List,
  DatePicker,
  Select,
  Line,
  Button
} from 'components/HrForms'

const process_office = office => {
  if (!office) return
  return ({
    id: office.id,
    name: office.full_name
  })
}

const process_position = position => {
  if (!position) return
  return  ({
    id: position.id,
    name: position.name
  })
}

const genders = [
  {id: 'None', name: 'N/A'},
  {id: 'Male', name: 'M'},
  {id: 'Female', name: 'F'}
]

const process_gender = gender => {
  if (!gender) return null
  if (gender.id === genders[0].id) return null
  return gender.id
}

const process_date = date => {
  if (!date || date === '') return null
  return new Date(date)
}

const process_devices = devices => (devices || []).map(device => ({
  id: device.id,
  name: device.serial_number
}))

const load_device = async id => {
  try {
    const loaded_res = await devices_api
      .get_by_id(id)
      .then(res => res.json())
    if (loaded_res.Success) {
      return loaded_res.Success
    } else if (loaded_res.IsAbsent) {
      enqueueSnackbar('Invalid device ID', {variant: 'error'})
    } else {
      enqueueSnackbar('Invalid query', {variant: 'error'})
    }
  } catch(err) {
    console.log(err)
    enqueueSnackbar('System error', {variant: 'error'})
  }
}

const update_device = async device => {
  try {
    const save_res = await devices_api
      .update(device)
      .then(res => res.json())
    if (save_res.Success) {
      enqueueSnackbar('Device successfully updated', {variant: 'success'})
      return true
    } else if (save_res.IsAbsent) {
      enqueueSnackbar('Invalid device ID', {variant: 'error'})
    } else {
      enqueueSnackbar('Invalid update', {variant: 'error'})
    }
  } catch(err) {
    console.log(err)
    enqueueSnackbar('System error', {variant: 'error'})
  }
}

const PersonForm = ({person, onSave, onReload}) => {
  const first_name = useField('')
  const family_name = useField('')
  const last_name = useField('')
  const passport_series = useField('')
  const passport_number = useField('')
  const passport_issue_office = useField('')
  const passport_office_code = useField('')
  const passport_date_of_issue = useField(new Date())
  const actual_address = useField('')
  const date_of_birth = useField()
  const gender = useField('')
  const personal_number = useField('')
  const family_status = useField('')
  const clearance_category = useField('')
  const phone = useField('')
  const email = useField('')
  const login = useField()
  const password = useField()
  const position = useField()
  const office = useField()

  const [offices, set_offices] = useState([])
  const [positions, set_positions] = useState([])
  const [remove_device, set_remove_device] = useState()
  const [is_device_select, set_is_device_select] = useState(false)

  const navigate = useNavigate()

  const load_offices = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await offices_api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_offices(loaded_res.Success.models.map(model => process_office(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_positions = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await positions_api
        .get_page(args)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_positions(loaded_res.Success.models.map(model => process_position(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    if (!person) return
    first_name.change(person.first_name)
    family_name.change(person.family_name)
    last_name.change(person.last_name)
    passport_series.change(person.passport_series)
    passport_number.change(person.passport_number)
    passport_issue_office.change(person.passport_issue_office)
    passport_office_code.change(person.passport_office_code)
    //passport_date_of_issue.change('2001-01-01')
    actual_address.change(person.actual_address)
    date_of_birth.change(process_date(person.date_of_birth))
    gender.change(genders.find(g => g.id === person.gender))
    personal_number.change(person.personal_number)
    family_status.change(person.family_status)
    clearance_category.change(person.clearance_category)
    phone.change(person.phone)
    email.change(person.email)
    position.change(process_position(person.position))
    office.change(process_office(person.office))
  }, [person])

  const on_save = () => {
    const errors = []
    errors.push(first_name.validate_empty())
    errors.push(family_name.validate_empty())
    errors.push(passport_series.validate_number_opt())
    errors.push(passport_number.validate_number_opt())
    errors.push(phone.validate_phone_opt())
    errors.push(email.validate_email())
    if (!(person && person.id)) {
      errors.push(login.validate_empty())
      errors.push(password.validate_empty())
    }
    if (errors.some(err => err)) return
    onSave({
      first_name: first_name.value,
      family_name: family_name.value,
      last_name: last_name.value,
      passport_series: passport_series.value,
      passport_number: passport_number.value,
      passport_issue_office: passport_issue_office.value,
      passport_office_code: passport_office_code.value,
      passport_date_of_issue: new Date().toISOString().split('T')[0],
      actual_address: actual_address.value,
      date_of_birth: date_of_birth.value?.toISOString().split('T')[0],
      gender: process_gender(gender.value),
      personal_number: personal_number.value,
      family_status: family_status.value,
      clearance_category: clearance_category.value,
      position_id: position.value?.id,
      phone: phone.value,
      email: email.value,
      office_id: office.value?.id,
      login: login.value,
      password: password.value
    })
  }

  const on_device_add = () => {
    navigate(`/hr/person/${person.id}/device`)
  }

  const on_device_select_open = async () => {
    set_is_device_select(true)
  }

  const on_device_select = async full_device => {
    const device = await load_device(full_device.id)
    if (!device) return
    device.user_id = person.id
    device.device_type = device.device_type.id
    const update_res = await update_device(device)
    if (update_res) {
      onReload()
    }
    set_is_device_select(false)
  }

  const on_cancel_select_device = () => {
    set_is_device_select(false)
  }

  const on_device_edit = device => {
    navigate(`/hr/device/${device.id}`)
  }

  const on_device_remove = device => {
    set_remove_device(device)
  }

  const on_cancel_remove_device = () => {
    set_remove_device()
  }

  const on_aprove_remove_device = async () => {
    if (!remove_device) return
    const device = await load_device(remove_device.id)
    if (!device) return
    device.user_id = null
    device.device_type = device.device_type.id
    const update_res = await update_device(device)
    if (update_res) {
      onReload()
    }
    set_remove_device()
  }

  return (
    <>
      <div className="hr_form person_form block-one">
        <Title>{fields.family_name}</Title>
        <Field
          value={family_name.value}
          isError={family_name.is_error}
          onChange={e => family_name.change(e.target.value)}
        />
        <Title>{fields.first_name}</Title>
        <Field
          value={first_name.value}
          isError={first_name.is_error}
          onChange={e => first_name.change(e.target.value)}
        />
      {person && person.id ? (
        <>
          <Title>{fields.last_name}</Title>
          <Field
            value={last_name.value}
            isError={last_name.is_error}
            onChange={e => last_name.change(e.target.value)}
          />
        </>
      ) : null}
      </div>
      {person && person.id ? null : (
        <div className="hr_form person_form block-one">
          <Title>{fields.login}</Title>
          <Field
            value={login.value}
            isError={login.is_error}
            onChange={e => login.change(e.target.value)}
          />
          <Title>{fields.password}</Title>
          <Field
            value={password.value}
            isError={password.is_error}
            onChange={e => password.change(e.target.value)}
          />
        </div>
      )}
      {person && person.id ? (
        <>
          <div className="hr_form person_form block-two">
            {/* <Title>{fields.passport_series}</Title>
            <Field
              value={passport_series.value}
              isError={passport_series.is_error}
              onChange={e => passport_series.change(e.target.value)}
            /> */}
            <Title>{fields.passport_number}</Title>
            <Field
              value={passport_number.value}
              isError={passport_number.is_error}
              onChange={e => passport_number.change(e.target.value)}
            />
            {/* <Title>{fields.passport_issue_office}</Title>
            <Field
              value={passport_issue_office.value}
              isError={passport_issue_office.is_error}
              onChange={e => passport_issue_office.change(e.target.value)}
            /> */}
          </div>
          <div className="hr_form person_form block-three">
            {/* <Title>{fields.passport_office_code}</Title>
            <Field
              value={passport_office_code.value}
              isError={passport_office_code.is_error}
              onChange={e => passport_office_code.change(e.target.value)}
            /> */}
            <Title>{fields.actual_address}</Title>
            <Field
              value={actual_address.value}
              isError={actual_address.is_error}
              onChange={e => actual_address.change(e.target.value)}
            />
          </div>
          <div className="hr_form person_form block-four">
            <Title>{fields.date_of_birth}</Title>
            <DatePicker
              value={date_of_birth.value}
              isError={date_of_birth.is_error}
              onChange={date => date_of_birth.change(date)}
              emptyValue={"N/A"}
            />
            <Title>{fields.gender}</Title>
            <Select
              value={gender.value}
              values={genders}
              isError={gender.is_error}
              onChange={val => gender.change(val)}
              emptyValue={"N/A"}
            />
            <Title>{fields.personal_number}</Title>
            <Field
              value={personal_number.value}
              isError={personal_number.is_error}
              onChange={e => personal_number.change(e.target.value)}
            />
            <Title>{fields.family_status}</Title>
            <Field
              value={family_status.value}
              isError={family_status.is_error}
              onChange={e => family_status.change(e.target.value)}
            />
          </div>
          <div className="hr_form person_form block-five">
            <Title>{fields.office}</Title>
            <SearchField
              value={office.value}
              emptyValue
              values={offices}
              onSearch={load_offices}
              onChange={val => office.change(val)}
            />
            <Title>{fields.clearance_category}</Title>
            <Field
              value={clearance_category.value}
              isError={clearance_category.is_error}
              onChange={e => clearance_category.change(e.target.value)}
            />
            <Title>{fields.position}</Title>
            <SearchField
              value={position.value}
              emptyValue
              values={positions}
              onSearch={load_positions}
              onChange={val => position.change(val)}
            />
          </div>
          <div className="hr_form person_form block-six">
            <Title>{fields.phone}</Title>
            <Field
              value={phone.value}
              isError={phone.is_error}
              onChange={e => phone.change(e.target.value)}
            />
            <Title>{fields.email}</Title>
            <Field
              value={email.value}
              isError={email.is_error}
              onChange={e => email.change(e.target.value)}
            />
          </div>
          <div className="hr_form person_form block-eight">
            <Title>{fields.devices}</Title>
            <List
              values={process_devices(person?.devices)}
              onAdd={on_device_add}
              onSelect={on_device_select_open}
              onEdit={on_device_edit}
              onRemove={on_device_remove}
            />
          </div>
        </>
      ) : (
        <div className="hr_form person_form block-one">
          <Title>{fields.email}</Title>
          <Field
            value={email.value}
            isError={email.is_error}
            onChange={e => email.change(e.target.value)}
          />
        </div>
      )}
      <Line className="hr_form line" />
      <div className="hr_form person_form block-seven">
        <Button className="save_button" onClick={on_save}>SAVE CHANGES</Button>
      </div>
      {remove_device ? (
        <HrModal
          title="Delete"
          onClose={on_cancel_remove_device}
        >
          <HrDialog
            title={`Delete device "${remove_device.name}"?`}
            onRemove={on_aprove_remove_device}
            onCancel={on_cancel_remove_device}
          />
        </HrModal>
      ) : null}
      {is_device_select ? (
        <HrModal
          title="Select"
          onClose={on_cancel_select_device}
        >
          <DeviceSelect onSelect={on_device_select} onCancel={on_cancel_select_device} />
        </HrModal>
      ) : null}
    </>
  )
}

export default PersonForm
