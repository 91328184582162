import React from 'react';
import Status from './Status';
import {useNavigate} from "react-router-dom"
import '../css/Avatar.css';

const Avatar = ({className, status, avatarSrc}) => {
    const navigate = useNavigate()

    const on_click = () => {
      navigate('/profile')
    }

    return (
        <div className={`avatar ${className}`} onClick={on_click}> 
            <div className={`avatar__contour ${className}`}>
                {
                    avatarSrc && 
                    <img className={`avatar__image ${className}`} src={avatarSrc} alt='avatar_img'/>
                } 
                {status && (
                    <div className='avatar__status__container'>
                        <Status color='#4BB56F' stroke='#F2F2F2' strokeWidth={2}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Avatar;
