import React from 'react';
import { LuPencilLine } from 'react-icons/lu';
import Avatar from './Avatar';
import Status from './Status';
import '../css/Profile.css';

import { useOutletContext } from 'react-router-dom';
import { get_age } from '../utils/functions';

const Profile = () => {
    const { profile, is_avatar_exist } = useOutletContext()

    const avatar_url = is_avatar_exist ? `/api/v1/hr/avatars/${profile.id}` : ''

    return (
        <div className='profile__info__container'>
            {
                profile && (
                    <>
                        <div className='profile__info__connection'>
                            Active  |  Online
                        </div>
                        <div className='profile__info'>
                            <div className='profile__info__data'>
                                <div className='profile__info__data__item'>
                                    {profile.personal_number ? `№ ${profile.personal_number}` : ''}
                                </div>
                                <div className='profile__info__data__item'>
                                    {profile.gender}{get_age(profile.date_of_birth)}
                                </div>
                                {/* <LuPencilLine color='#717171' size={20}/> */}
                            </div>
                            <div className='profile_avatar_conteiner'>
                                <Avatar className='profile_avatar' avatarSrc={avatar_url} status />
                                <div className='profile_avatar_user'>
                                    <div className='profile_avatar_user__name'>
                                        <div className='user__first_name'>
                                            {profile.first_name}
                                        </div>
                                        <div className='user__family_name'>
                                            {profile.family_name}
                                        </div>
                                    </div>
                                    <div className='user__indecator'>
                                        <Status color='#4BB56F' stroke='#00923A' strokeWidth={0.5}/>
                                        <Status color='#BDBDBD' stroke='#868686' strokeWidth={0.5}/>
                                        <Status color='#BDBDBD' stroke='#868686' strokeWidth={0.5}/>
                                    </div>
                                </div>
                            </div>
                            <div className='profile__info__data'>
                                <div className='profile__info__data__item'>
                                    <div className='data__item__beat'>75</div>
                                    <div>b/min</div>
                                </div>
                                <div className='profile__info__data__item'>
                                    {/* <span style={{fontSize: 16,
                                                fontWeight: 500}}>23</span>
                                    <span>м, Altitude</span> */}
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default Profile;