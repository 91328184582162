import {useState, useEffect} from 'react'
import {useParams} from "react-router-dom"
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {companies as api} from 'API/hr'
import Header from 'components/Header'
import HrMenu from 'components/HrMenu'
import HrTitle from 'components/HrTitle'
import CompanyForm from 'components/CompanyForm'
import CompanyCard from 'components/CompanyCard'

import {HR_VIEW_MODE, HR_EDIT_MODE} from 'utils/constants'

const Company = () => {
  const [company, set_company] = useState()
  const [mode, set_mode] = useState(HR_EDIT_MODE)

  const {id} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const load_company = async () => {
    if (id) {
      try {
        const loaded_res = await api
          .get_by_id(id)
          .then(res => res.json())
        if (loaded_res.Success) {
          set_company(loaded_res.Success)
        } else if (loaded_res.IsAbsent) {
          enqueueSnackbar('Invalid company ID', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid query', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    }
  }

  useEffect(() => {
    load_company()
  }, [id])

  const save_company = async values => {
    if (id) {
      try {
        const save_res = await api
          .update({id: company.id, ...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('Company information updated', {variant: 'success'})
          load_company()
        } else if (save_res.IsAbsent) {
          enqueueSnackbar('Invalid company ID', {variant: 'error'})
        } else if (save_res.IsExistByField) {
          enqueueSnackbar('A company with this name already exists', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else {
      try {
        const save_res = await api
          .create({...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('Company successfully saved', {variant: 'success'})
          navigate(`/hr/company/${save_res.Success}`)
        } else if (save_res.IsExist) {
          enqueueSnackbar('A company with this name already exists', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    }
  }

  const on_view = () => set_mode(HR_VIEW_MODE)
  const on_edit = () => set_mode(HR_EDIT_MODE)

  const on_back = () => {
    navigate('/hr/companies')
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Personnel records</Header>
      <HrMenu />
      <div className="hr_page">
        <HrTitle
          mode={mode}
          onView={on_view}
          onEdit={on_edit}
          onBack={on_back}
        >{company ? company.name : 'Business name'}</HrTitle>
        {mode === HR_EDIT_MODE ? (
          <CompanyForm
            company={company}
            onSave={save_company}
            onReload={load_company}
          />
        ) : null}
        {mode === HR_VIEW_MODE ? (
          <CompanyCard className="hr_page--card" company={company} />
        ) : null}
      </div>
    </div>
  )
}

export default Company
