import { useState, useEffect } from 'react'
import SelectTime from './SelectTime'
import { IoMdTime } from "react-icons/io"
import { lead_zero } from 'utils/functions'

import DataService from 'API/DataService'

import '../css/PeriodTime.css'

const PeriodTime = ({ className, time, setTime, date }) => {
  const [hlHours, setHlHours] = useState([])

  useEffect(() => {
    if (!date) return
    (async () => {
      const sendDate = new Date(date.getTime())
      sendDate.setHours(0, 0, 0, 0)
      const existingTimestamps = await DataService.getExistingEcg(sendDate.toISOString(), 1440, 3600)
      const hlHours = existingTimestamps.map(t => lead_zero(new Date(t).getHours()))
      setHlHours(hlHours)
    })()
  }, [date])

  return (
    <div className={[className, 'Period-time'].join(' ')}>
      <IoMdTime color='#3D7CB6' size='24px'></IoMdTime>
      <SelectTime placeholder='hh'
        time={time}
        setTime={setTime}
        hlHours={hlHours} />
      <span>:</span>
      <SelectTime placeholder='mm'
        time={time}
        setTime={setTime}
        minutes />
    </div>
  )
}

export default PeriodTime
