export const fields = {
  serial_number: 'Wearable device ID',
  device_type: 'Wearable device type',
  date_of_issue: 'Device assignment date',
  sim_number: 'Sim-card serial number',
  last_connection: 'Last online',
  contract: 'Supply contract',
  user_id: 'Employee - device user',
  user: 'Employee - device user'
}

export const order = [
  'serial_number',
  'device_type',
  'date_of_issue',
  'sim_number',
  'last_connection',
  'contract',
  'user_id'
]

export const table = [
  'serial_number',
  'device_type',
  'date_of_issue',
  'sim_number',
  'last_connection',
  'contract',
  'user'
]
