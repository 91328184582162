import {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'

import '../css/HrMenu.css'

const HrMenu = () => {
  const location = useLocation()
  const [current, set_current] = useState('')

  useEffect(() => {
    const parts = location.pathname.split('/')
    if (parts.length > 4) {
      set_current(parts[4])
    } else {
      set_current(parts[2])
    }
  }, [location])

  const is_active = route => current.startsWith(route) ? 'active' : '' 

  return (
    <div className="hr_menu">
      <div className="hr_menu__container">
        <Link to="/hr/companies" className={`hr_menu__item companies ${is_active('compan')}`}>Business entity</Link>
        <Link to="/hr/departments" className={`hr_menu__item departments ${is_active('department')}`}>Divisions</Link>
        <Link to="/hr/offices" className={`hr_menu__item offices ${is_active('office')}`}>Departments</Link>
        <Link to="/hr/persons" className={`hr_menu__item persons ${is_active('person')}`}>Employees</Link>
        <Link to="/hr/devices" className={`hr_menu__item divices ${is_active('device')}`}>Wearable devices directory</Link>
        <Link to="/hr/positions" className={`hr_menu__item positions ${is_active('position')}`}>Position directory</Link>
      </div>
    </div>
  )
}

export default HrMenu
