import {useState, useEffect} from 'react'
import {useParams} from "react-router-dom"
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import {
  persons as api,
  offices as offices_api
} from 'API/hr'
import Header from 'components/Header'
import HrMenu from 'components/HrMenu'
import HrTitle from 'components/HrTitle'
import PersonForm from 'components/PersonForm'
import PersonCard from 'components/PersonCard'

import {HR_VIEW_MODE, HR_EDIT_MODE} from 'utils/constants'

const get_full_name = person => {
  const {
    family_name,
    first_name,
    last_name
  } = person
  return `${family_name} ${first_name} ${last_name}`
}

const Person = () => {
  const [person, set_person] = useState()
  const [mode, set_mode] = useState(HR_EDIT_MODE)

  const {id, office_id} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const load_person = async () => {
    if (id) {
      try {
        const loaded_res = await api
          .get_by_id(id)
          .then(res => res.json())
        if (loaded_res.Success) {
          set_person(loaded_res.Success)
        } else if (loaded_res.IsAbsent) {
          enqueueSnackbar('Invalid employee ID', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid query', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else if (office_id) {
      load_office()
    }
  }

  const load_office = async () => {
    try {
      const loaded_res = await offices_api
        .get_by_id(office_id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_person({
          first_name: '',
          family_name: '',
          last_name: '',
          passport_series: '',
          passport_number: '',
          passport_issue_office: '',
          passport_office_code: '',
          passport_date_of_issue: '',
          actual_address: '',
          date_of_birth: '',
          gender: 'Male',
          personal_number: '',
          family_status: '',
          clearance_category: '',
          phone: '',
          email: '',
          office: {
            id: loaded_res.Success.id,
            full_name: loaded_res.Success.full_name
          }
        })
      } else if (loaded_res.IsAbsent) {
        enqueueSnackbar('Invalid department ID', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    load_person()
  }, [id])

  const save_person = async values => {
    if (id) {
      try {
        const save_res = await api
          .update({id: person.id, ...values})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('Employee information updated', {variant: 'success'})
          load_person()
        } else if (save_res.IsAbsent) {
          enqueueSnackbar('Invalid employee ID', {variant: 'error'})
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    } else {
      try {
        const data = {...values}
        delete data.login
        delete data.password
        const save_res = await api
          .create(data, {login: values.login, password: values.password})
          .then(res => res.json())
        if (save_res.Success) {
          enqueueSnackbar('Employee successfully saved', {variant: 'success'})
          navigate(`/hr/person/${save_res.Success}`)
        } else {
          enqueueSnackbar('Invalid update', {variant: 'error'})
        }
      } catch(err) {
        console.log(err)
        enqueueSnackbar('System error', {variant: 'error'})
      }
    }
  }

  const on_view = () => set_mode(HR_VIEW_MODE)
  const on_edit = () => set_mode(HR_EDIT_MODE)

  const on_back = () => {
    navigate('/hr/persons')
  }

  return (
    <div className="layout-page hr_layout">
      <Header>Personnel records</Header>
      <HrMenu />
      <div className="hr_page">
        <HrTitle
          mode={mode}
          onView={on_view}
          onEdit={on_edit}
          onBack={on_back}
        >{person && person.id ? get_full_name(person) : 'Employee name'}</HrTitle>
        {mode === HR_EDIT_MODE ? (
          <PersonForm
            person={person}
            onSave={save_person}
            onReload={load_person}
          />
        ) : null}
        {mode === HR_VIEW_MODE ? (
          <PersonCard className="hr_page--card" person={person} />
        ) : null}
      </div>
    </div>
  )
}

export default Person
