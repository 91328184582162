import {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import {enqueueSnackbar} from 'notistack'

import '../css/CompanyForm.css'
import {
  positions as positions_api,
  departments as departments_api,
  persons as persons_api
} from 'API/hr'
import HrModal from 'components/HrModal'
import HrDialog from 'components/HrDialog'

import {useField} from 'hooks/useField'
import {fields} from 'utils/company.fields'
import {
  COMPANY_CHIEF,
  COMPANY_ADMIN,
  COMPANY_HR
} from 'utils/position.fields'
import {
  Title,
  Field,
  SearchField,
  List,
  Line,
  Button
} from 'components/HrForms'

const process_departments = departments => {
  if (!departments) return []
  return departments.map(item => ({id: item.id, name: item.full_name}))
}

const process_position = position => {
  if (!position) return
  return  ({
    id: position.id,
    name: position.name
  })
}

const process_person = person => {
  if (!person) return
  const {
    first_name,
    family_name,
    last_name
  } = person
  return ({
    id: person.id,
    name: `${family_name} ${first_name} ${last_name}`
  })
}

const CompanyForm = ({company, onSave, onReload}) => {
  const name = useField('')
  const short_name = useField('')
  const legal_address = useField('')
  const actual_address = useField('')
  const inn = useField('')
  const ogrn = useField('')
  const kpp = useField('')
  const okpo = useField('')
  const okved = useField('')
  const parent_organization = useField('')
  const phone = useField('')
  const phone_additional = useField('')
  const email = useField('')
  const chief_position = useField()
  const chief_person = useField()
  const admin_person = useField()
  const hr_person = useField()

  const [remove_department, set_remove_department] = useState()
  const [positions, set_positions] = useState([])
  const [chief_persons, set_chief_persons] = useState([])
  const [admin_persons, set_admin_persons] = useState([])
  const [hr_persons, set_hr_persons] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (!company) return
    name.change(company.name)
    short_name.change(company.short_name)
    legal_address.change(company.legal_address)
    actual_address.change(company.actual_address)
    inn.change(company.inn)
    ogrn.change(company.ogrn)
    kpp.change(company.kpp)
    okpo.change(company.okpo)
    okved.change(company.okved)
    parent_organization.change(company.parent_organization)
    phone.change(company.phone)
    phone_additional.change(company.phone_additional)
    email.change(company.email)
    chief_position.change(process_position(company.chief_position))
    chief_person.change(process_person(company.chief_person))
    admin_person.change(process_person(company.admin_person))
    hr_person.change(process_person(company.hr_person))
  }, [company])

  const load_positions = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query: query || ''
    }
    try {
      const loaded_res = await positions_api
        .get_page(args, COMPANY_CHIEF)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_positions(loaded_res.Success.models.map(model => process_position(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_chief_persons = async query => {
    if (!chief_position.value) return
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query
    }
    try {
      const loaded_res = await persons_api
        .get_page(args, chief_position.value?.id)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_chief_persons(loaded_res.Success.models.map(model => process_person(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  useEffect(() => {
    if (!chief_position.value) {
      set_chief_persons([])
      chief_person.change()
    }
    else load_chief_persons()
  }, [chief_position.value])

  const load_admin_persons = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query
    }
    try {
      const loaded_res = await persons_api
        .get_page(args, null, COMPANY_ADMIN)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_admin_persons(loaded_res.Success.models.map(model => process_person(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const load_hr_persons = async query => {
    const args = {
      page: {page: 0, page_size: 5},
      order: {column: null, direction: null},
      query
    }
    try {
      const loaded_res = await persons_api
        .get_page(args, null, COMPANY_HR)
        .then(res => res.json())
      if (loaded_res.Success) {
        set_hr_persons(loaded_res.Success.models.map(model => process_person(model)))
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
  }

  const on_save = () => {
    const errors = []
    errors.push(name.validate_empty())
    errors.push(short_name.validate_empty())
    errors.push(legal_address.validate_empty())
    errors.push(actual_address.validate_empty())
    errors.push(inn.validate_number())
    errors.push(ogrn.validate_number())
    errors.push(kpp.validate_number())
    errors.push(okpo.validate_number())
    errors.push(okved.validate_number())
    errors.push(parent_organization.validate_empty())
    errors.push(phone.validate_phone())
    errors.push(phone_additional.validate_phone())
    errors.push(email.validate_email())
    if (errors.some(err => err)) return
    onSave({
      name: name.value,
      short_name: short_name.value,
      legal_address: legal_address.value,
      actual_address: actual_address.value,
      inn: inn.value,
      ogrn: ogrn.value,
      kpp: kpp.value,
      okpo: okpo.value,
      okved: okved.value,
      parent_organization: parent_organization.value,
      phone: phone.value.replace(/[^\d]/g, ''),
      phone_additional: phone_additional.value.replace(/[^\d]/g, ''),
      email: email.value,
      chief_position_id: chief_position.value?.id,
      chief_person_id: chief_person.value?.id,
      admin_person_id: admin_person.value?.id,
      hr_person_id: hr_person.value?.id
    })
  }

  const on_department_add = () => {
    navigate(`/hr/company/${company.id}/department`)
  }

  const on_department_edit = department => {
    navigate(`/hr/department/${department.id}`)
  }

  const on_department_remove = department => {
    set_remove_department(department)
  }

  const on_cancel_remove = () => {
    set_remove_department()
  }

  const on_aprove_remove = async () => {
    try {
      const remove_res = await departments_api
        .remove(remove_department.id)
        .then(res => res.json())
      if (remove_res.Success) {
        onReload()
        enqueueSnackbar('Division successfully deleted', {variant: 'success'})
      } else if (remove_res.IsAbsent) {
        enqueueSnackbar('Invalid division ID', {variant: 'error'})
      } else if (remove_res.NotEmpty) {
        enqueueSnackbar('Deletion faild. Related objects found', {variant: 'error'})
      } else {
        enqueueSnackbar('Invalid query', {variant: 'error'})
      }
    } catch(err) {
      console.log(err)
      enqueueSnackbar('System error', {variant: 'error'})
    }
    set_remove_department()
  }

  return (
    <>
      <div className="hr_form company_form block-one">
        <Title>{fields.name}</Title>
        <Field
          value={name.value}
          isError={name.is_error}
          onChange={e => name.change(e.target.value)}
        />
        {/* <Title>{fields.short_name}</Title>
        <Field
          value={short_name.value}
          isError={short_name.is_error}
          onChange={e => short_name.change(e.target.value)}
        /> */}
        <Title>{fields.legal_address}</Title>
        <Field
          value={legal_address.value}
          isError={legal_address.is_error}
          onChange={e => legal_address.change(e.target.value)}
        />
        <Title>{fields.actual_address}</Title>
        <Field
          value={actual_address.value}
          isError={actual_address.is_error}
          onChange={e => actual_address.change(e.target.value)}
        />
      </div>
      {/* <div className="hr_form company_form block-two">
        <Title>{fields.inn}</Title>
        <Field
          value={inn.value}
          isError={inn.is_error}
          onChange={e => inn.change(e.target.value)}
        />
        <Title>{fields.ogrn}</Title>
        <Field
          value={ogrn.value}
          isError={ogrn.is_error}
          onChange={e => ogrn.change(e.target.value)}
        />
        <Title>{fields.kpp}</Title>
        <Field
          value={kpp.value}
          isError={kpp.is_error}
          onChange={e => kpp.change(e.target.value)}
        />
        <Title>{fields.okpo}</Title>
        <Field
          value={okpo.value}
          isError={okpo.is_error}
          onChange={e => okpo.change(e.target.value)}
        />
        <Title>{fields.okved}</Title>
        <Field
          value={okved.value}
          isError={okved.is_error}
          onChange={e => okved.change(e.target.value)}
        />
      </div> */}
      <div className="hr_form company_form block-three">
        <Title>{fields.chief_position}</Title>
        <SearchField
          value={chief_position.value}
          thik
          emptyValue
          values={positions}
          onSearch={load_positions}
          onChange={val => chief_position.change(val)}
        />
        <Title>{fields.chief_person}</Title>
        <SearchField
          value={chief_person.value}
          thik
          emptyValue
          values={chief_persons}
          onSearch={load_chief_persons}
          onChange={val => chief_person.change(val)}
        />
      </div>
      <div className="hr_form company_form block-four">
        <Title>{fields.parent_organization}</Title>
        <Field
          value={parent_organization.value}
          isError={parent_organization.is_error}
          onChange={e => parent_organization.change(e.target.value)}
        />
      </div>
      <div className="hr_form company_form block-five">
        <Title>{fields.phone}</Title>
        <Field
          value={phone.value}
          isError={phone.is_error}
          onChange={e => phone.change(e.target.value)}
        />
        <Title>{fields.email}</Title>
        <Field
          value={email.value}
          isError={email.is_error}
          onChange={e => email.change(e.target.value)}
        />
        <Title>{fields.phone_additional}</Title>
        <Field
          value={phone_additional.value}
          isError={phone_additional.is_error}
          onChange={e => phone_additional.change(e.target.value)}
        />
      </div>
      <div className="hr_form company_form block-six">
        <Title>{fields.admin_person}</Title>
        <SearchField
          value={admin_person.value}
          thik
          emptyValue
          values={admin_persons}
          onSearch={load_admin_persons}
          onChange={val => admin_person.change(val)}
        />
        <Title>{fields.hr_person}</Title>
        <SearchField
          value={hr_person.value}
          thik
          emptyValue
          values={hr_persons}
          onSearch={load_hr_persons}
          onChange={val => hr_person.change(val)}
        />
      </div>
      {(company && company.id) ? (
        <div className="hr_form company_form block-seven">
          <Title>{fields.departments}</Title>
          <List
            values={process_departments(company?.departments)}
            onAdd={on_department_add}
            onEdit={on_department_edit}
            onRemove={on_department_remove}
          />
        </div>
      ) : null}
      <Line className="hr_form line" />
      <div className="hr_form company_form block-eight">
        <Button className="save_button" onClick={on_save}>SAVE CHANGES</Button>
      </div>
      {remove_department ? (
        <HrModal
          title="Delete"
          onClose={on_cancel_remove}
        >
          <HrDialog
            title={`Delete division "${remove_department.name}"?`}
            onRemove={on_aprove_remove}
            onCancel={on_cancel_remove}
          />
        </HrModal>
      ) : null}
    </>
  )
}

export default CompanyForm
