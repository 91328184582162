import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Summary from "./pages/Summary";
import HealthLog from "./pages/HealthLog";
import AccessLog from "./pages/AccessLog";
import Ecg from "./pages/Ecg";
import EcgHistory from "./pages/EcgHistory";
import Maps from "./pages/Maps";
import Main from "./pages/Main";
import ReportingData from "./pages/ReportingData";
import Login from "./pages/Login";
import { useEffect, useState } from "react";
import { AuthContext } from "context";
import MainHealthLog from "pages/MainHealthLog";
import MainAccessLog from "pages/MainAccessLog";
import NameHealthLog from "pages/NameHealthLog";
import EventHealthLog from "pages/EventHealthLog";
import NameAccessLog from "pages/NameAccessLog";
import EventAccessLog from "pages/EventAccessLog";
import Hr from "./pages/Hr";
import Company from "./pages/Company";
import Department from "./pages/Department";
import Office from "./pages/Office";
import Person from "./pages/Person";
import Device from "./pages/Device";
import Position from "./pages/Position";
import Companies from "./pages/Companies";
import Departments from "./pages/Departments";
import Offices from "./pages/Offices";
import Persons from "./pages/Persons";
import Devices from "./pages/Devices";
import Positions from "./pages/Positions";
import Profile from "./pages/Profile";
import { SnackbarProvider } from 'notistack'

import 'css/HrPage.css'
import { ApmRoutes } from "@elastic/apm-rum-react";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuth(true);
    }
    setIsLoading(false);
  }, [])

  if (isLoading) {
    return (
      <></>
    )
  }

  return (
    <AuthContext.Provider value={{
      isAuth,
      setIsAuth
    }}>
      <BrowserRouter>
        {isAuth
          ?
          <ApmRoutes>
            <Route path='/' element={<Main />}>
              <Route index element={<Navigate replace to='report' />} />
              <Route path='report' element={<ReportingData />}>
                <Route index element={<Navigate replace to='summary' />} />
                <Route path='summary' element={<Summary />} />
                <Route path='health-log' element={<HealthLog />} />
                <Route path='access-log' element={<AccessLog />} />
                {/*<Route path='ecg' element={<Ecg/>}/>*/}
                <Route path='diagnostics' element={<EcgHistory />} />
                <Route path='maps' element={<Maps />} />
              </Route>
              <Route path='health-log' element={<MainHealthLog />}>
                <Route index element={<Navigate replace to='name' />} />
                <Route path='name' element={<NameHealthLog />} />
                <Route path='event' element={<EventHealthLog />} />
              </Route>
              <Route path='access-log' element={<MainAccessLog />}>
                <Route index element={<Navigate replace to='name' />} />
                <Route path='name' element={<NameAccessLog />} />
                <Route path='event' element={<EventAccessLog />} />
              </Route>
              <Route path='hr' element={<Hr />}>
                <Route index element={<Navigate replace to='companies' />} />
                <Route path='companies' element={<Companies />} />
                <Route path='departments' element={<Departments />} />
                <Route path='offices' element={<Offices />} />
                <Route path='persons' element={<Persons />} />
                <Route path='devices' element={<Devices />} />
                <Route path='positions' element={<Positions />} />
                <Route path='company/:id?' element={<Company />} />
                <Route path='company/:company_id/department' element={<Department />} />
                <Route path='department/:id?' element={<Department />} />
                <Route path='department/:department_id/office' element={<Office />} />
                <Route path='office/:id?' element={<Office />} />
                <Route path='office/:office_id/person' element={<Person />} />
                <Route path='person/:id?' element={<Person />} />
                <Route path='person/:person_id/device' element={<Device />} />
                <Route path='device/:id?' element={<Device />} />
                <Route path='position/:id?' element={<Position />} />
              </Route>
              <Route path='employees' />
              <Route path='employee-location' />
              <Route path='personnel-accounting' />
              <Route path='handbooks' />
              <Route path='profile' element={<Profile />} />
              <Route path='*' element={<Navigate replace to='report/summary' />} />
            </Route>
          </ApmRoutes>
          :
          <ApmRoutes>
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<Navigate replace to='/login' />} />
          </ApmRoutes>
        }
      </BrowserRouter>
      <SnackbarProvider />
    </AuthContext.Provider>
  );
}

export default App;
