import React from 'react';

import 'css/Switch.css'

const Switch = ({on, onClick}) => {
    return (
        <div className={`custom_switch${on ? ' on' : ''}`} onClick={onClick}>
          <div className="custom_thumb"></div>
        </div>
      )
};

export default Switch;