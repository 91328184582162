import React, { useState, useEffect } from 'react';
import Indicator from '../components/Indicator';
import RateTrend from '../components/RateTrend';
import Filter from '../components/Filter';
import Table from '../components/Table';
import { useFetching } from '../hooks/useFetching';
import DataService from '../API/DataService';
import { DELAY, COLORS, PATHOLOGY_DEF } from '../utils/constants';
import LogHeader from 'components/LogHeader';
import { useOutletContext } from 'react-router-dom';

const HealthLog = () => {
    const [integralIndicator, setIntegralIndicator] = useState({});
    const [deviceCharge, setDeviceCharge] = useState('');
    const {chartDate} = useOutletContext()

    const [fetchIntegralIndicator, integralIndicatorLoading, integralIndicatorError] = useFetching(async () => {
        const responseData = await DataService.getIntegralIndicatorData();
        setIntegralIndicator(responseData);
    });

    const [fetchDeviceCharge, deviceChargeLoading, deviceChargeError] = useFetching(async () => {
        const responseData = await DataService.getDeviceChargeData();
        setDeviceCharge(responseData);
    });

    const getData = () => {
        fetchIntegralIndicator();
        fetchDeviceCharge();
    };

    useEffect(() => {
        getData();
        const id = setInterval(getData, DELAY);
        return () => clearInterval(id);
    }, []);

    return (
        <div className='main__container__up'>
            <div className='main__container__left'>
                <RateTrend baseDate={chartDate?.date}/>
                {integralIndicatorError ?
                    <h2 style={{color: COLORS.red}}>{integralIndicatorError}</h2> :
                    <Indicator info={{name: 'Integrated health indicator'}}
                               data={integralIndicator}/>
                }
                {deviceChargeError ?
                    <h2 style={{color: COLORS.red}}>{deviceChargeError}</h2> :
                    <Indicator info={{name: 'Battery',
                                      reverse: true}}
                                data={{percent: deviceCharge}}/>
                }
            </div>
            <div className='main__container__right'>
                <div className='logs-container'>
                    <LogHeader>
                        Pathologies log
                    </LogHeader>
                    <Table columns={PATHOLOGY_DEF}
                           apiFunction={DataService.getPathologyData}
                           style={{height: 690}}/>
                </div>    
            </div>
        </div>
    );
};

export default HealthLog;