export const fields = {
  first_name: 'First name',
  family_name: 'Last name',
  last_name: 'Other names',
  full_name: 'Name',
  passport: 'Паспортные данные',
  passport_series: 'Серия',
  passport_number: 'Passport number',
  passport_issue_office: 'Кем выдан',
  passport_office_code: 'Код подразделения',
  passport_date_of_issue: 'Дата выдачи',
  actual_address: 'Home address',
  date_of_birth: 'Date of birth',
  gender: 'Gender',
  personal_number: 'Employee ID',
  family_status: 'Marital status',
  clearance_category: 'Clearance status',
  position: 'Employee position',
  position_id: 'Employee position',
  phone: 'Phone number',
  email: 'E-mail',
  office: 'Department',
  office_id: 'Department',
  devices: 'Wearable device',
  login: 'Login',
  password: 'Password'
}

export const order = [
  'full_name',
  'personal_number',
  'position',
  'office',
  'clearance_category'
]

export const table = [
  'full_name',
  'personal_number',
  'position',
  'office',
  'clearance_category'
]
