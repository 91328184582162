import '../css/HrDialog.css'

const HrDialog = (props) => {
  const {
    title,
    onSuccess,
    onRemove,
    onCancel
  } = props

  return (
    <div className="hr_dialog">
      <div className="hr_dialog--title">{title}</div>
      <div className="hr_dialog--buttons">
        {onSuccess ? (
          <button className="hr_dialog--button success" onClick={onSuccess}>Yes</button>
        ) : null}
        {onRemove ? (
          <button className="hr_dialog--button danger" onClick={onRemove}>Confirm</button>
        ) : null}
        {onCancel ? (
          <button className="hr_dialog--button" onClick={onCancel}>Cancel</button>
        ) : null}
      </div>
    </div>
  )
}

export default HrDialog
