import {HR_VIEW_MODE, HR_EDIT_MODE} from 'utils/constants'
import back_icon from 'assets/hr_back.svg'

import '../css/HrTitle.css'

const HrTitle = (props) => {
  const {
    children,
    mode,
    onView,
    onEdit,
    onBack
  } = props

  const get_edit_cls = () => `hr_title--item ${mode === HR_EDIT_MODE ? 'active' : ''}`
  const get_view_cls = () => `hr_title--item ${mode === HR_VIEW_MODE ? 'active' : ''}`

  return (
    <div className="hr_title">
      <img src={back_icon} className="hr_title--back_icon" onClick={onBack} />
      <div className="hr_title--devider"></div>
      <div className="hr_title--menu">
        <div className={get_edit_cls()} onClick={onEdit}>EDITING</div>
        <div className={get_view_cls()} onClick={onView}>VIEWING</div>
      </div>
      <div className="hr_title--devider"></div>
      <div className="hr_title--text">{children}</div>
    </div>
  )
}

export default HrTitle
